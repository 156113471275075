import clsx from "clsx";
import { Icon } from "@iconify/react";
import { useSnackbar } from "notistack";
import { PATH_APP } from "../../../routes/paths";
import MyAvatar from "../../../components/MyAvatar";
import React, { useRef, useState } from "react";
import Modal from "react-modal";
import homeFill from "@iconify-icons/eva/home-fill";
import PopoverMenu from "../../../components/PopoverMenu";
import { useDispatch, useSelector } from "react-redux";
import useIsMountedRef from "../../../hooks/useIsMountedRef";
import settings2Fill from "@iconify-icons/eva/settings-2-fill";
import { Link as RouterLink, useHistory } from "react-router-dom";
import { makeStyles, alpha } from "@material-ui/core/styles";
import { Button, Box, Divider, MenuItem, Typography } from "@material-ui/core";
import { MIconButton } from "../../../theme";
import HelpIcon from "@iconify-icons/eva/question-mark-circle-fill";
import jwt_decode from "jwt-decode";
import { logout } from "redux/slices/authJwt";
import { PATH_PAGE } from "routes/paths";
import { IRootState } from "redux/rootReducer";

// ----------------------------------------------------------------------

const MENU_OPTIONS = [
  {
    label: "Inicio",
    icon: homeFill,
    linkTo: "/",
  },
  {
    label: "Cuenta",
    icon: settings2Fill,
    linkTo: PATH_APP.management.user.account,
  },
  {
    label: "Ayuda",
    icon: HelpIcon,
    linkTo: process.env.REACT_APP_USER_DOCU_URL,
  },
  // {
  //   label: "Admin",
  //   icon: homeOutlined,
  //   linkTo: "https://medify-pharmacy-portal-prod.netlify.app/",
  // },
];

const useStyles = makeStyles((theme) => ({
  btnAvatar: {
    padding: 0,
    width: 44,
    height: 44,
  },
  isSelected: {
    "&:before": {
      zIndex: 1,
      content: "''",
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      position: "absolute",
      background: alpha(theme.palette.grey[900], 0.8),
    },
  },
}));

const customModalStyles = {
  content: {
    width: "70%",
    top: "55%",
    left: "60%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

// ----------------------------------------------------------------------

function Account() {
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();
  const anchorRef = useRef(null);
  const [openTutorialModal, setOpenTutorialModal] = useState(false);
  const isMountedRef = useIsMountedRef();
  const { enqueueSnackbar } = useSnackbar();
  const [isOpen, setOpen] = useState(false);
  let { accessToken, pharmacy } = useSelector(
    (state: IRootState) => state.authJwt
  );
  if (!accessToken || accessToken === "null") {
    history.push(PATH_PAGE.auth.login);
    return <div />;
  }

  const decoded = jwt_decode(accessToken);
  const fullName = `${decoded["firstName"]} ${decoded["lastName"]}`;

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleLogout = async () => {
    try {
      dispatch(logout());
      if (isMountedRef.current) {
        history.push("/");
        handleClose();
      }
    } catch (error) {
      console.log(error);
      enqueueSnackbar("Imposible cerrar sesión", {
        variant: "error",
        autoHideDuration: 3000,
      });
    }
  };

  return (
    <>
      <Modal
        isOpen={openTutorialModal}
        onAfterOpen={null}
        onRequestClose={() => setOpenTutorialModal(false)}
        style={customModalStyles}
        contentLabel="Example Modal"
      >
        <div
          id="tolstoy-container"
          style={{
            lineHeight: 0,
            overflow: "hidden",
            height: "100%",
            width: "100%",
            textAlign: "center",
          }}
        >
          <iframe
            title="totlstoy"
            id="tolstoy"
            src="https://player.gotolstoy.com/u8cv4zk8wg82k?host"
            style={{ width: "100%", height: "540px", maxWidth: "960px" }}
            scrolling="no"
            allow="autoplay *; clipboard-write *;camera *; microphone *; encrypted-media *; fullscreen *; display-capture *;"
          ></iframe>
          <script src="https://widget.gotolstoy.com/script.js" defer></script>
        </div>
      </Modal>
      <MIconButton
        ref={anchorRef}
        onClick={handleOpen}
        className={clsx(classes.btnAvatar, { [classes.isSelected]: isOpen })}
      >
        {pharmacy?.logoUrl ? (
          <img src={pharmacy.logoUrl} alt="pharmacy_logo" />
        ) : (
          <MyAvatar name={fullName} />
        )}
      </MIconButton>

      <PopoverMenu
        width={220}
        open={isOpen}
        onClose={handleClose}
        anchorEl={anchorRef.current}
      >
        {pharmacy && (
          <Box {...{ sx: { my: 2, px: 2.5 } }}>
            <Typography variant="subtitle1" noWrap>
              {pharmacy?.address?.address}
            </Typography>
            <Typography
              variant="body2"
              {...{ sx: { color: "text.secondary" } }}
              noWrap
            >
              Facturas Restantes: {pharmacy.availableInvoices}
            </Typography>
          </Box>
        )}

        <Divider {...({ sx: { my: 1 } } as any)} />
        <Box {...{ sx: { p: 2, pt: 1.5 } }}>
          <Button
            fullWidth
            color="inherit"
            variant="outlined"
            onClick={() => setOpenTutorialModal(true)}
          >
            Ver Tutorial
          </Button>
        </Box>

        {MENU_OPTIONS.map((option) => (
          <MenuItem
            key={option.label}
            to={option.linkTo}
            component={RouterLink}
            onClick={handleClose}
            sx={
              {
                typography: "body2",
                py: 1,
                px: 2.5,
              } as any
            }
          >
            <Box
              component={Icon}
              {...{
                icon: option.icon,
                sx: {
                  mr: 2,
                  width: 24,
                  height: 24,
                },
              }}
            />

            {option.label}
          </MenuItem>
        ))}

        <Box {...{ sx: { p: 2, pt: 1.5 } }}>
          <Button
            fullWidth
            color="inherit"
            variant="outlined"
            onClick={handleLogout}
          >
            Cerrar sesión
          </Button>
        </Box>
      </PopoverMenu>
    </>
  );
}

export default Account;
