import { sum, map, filter, uniqBy } from "lodash";
import axios from "../../utils/axios";
import { createSlice } from "@reduxjs/toolkit";
import {
  IGenericProduct,
  IGenericProductCart,
} from "lib/interfaces/generic-product";
import { IFilter } from "redux/shared/interfaces";
import {
  updateGenericProduct,
  createGenericProduct,
} from "lib/api/generic-product";
import { getBaseProductFromBarcode } from "lib/api/product";
import { CreateOrUpdateGenericProductDto } from "redux/slices/new-product";

// ----------------------------------------------------------------------

const apiURl = process.env.REACT_APP_API_URL;
axios.interceptors.request.use(
  function (config) {
    config.headers.Authorization = `Bearer ${localStorage.getItem(
      process.env.REACT_APP_ACCESS_TOKEN || "medify_access_token"
    )}`;
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

export interface DateRange {
  startDate: Date;
  endDate: Date;
}

const initialState = {
  isLoading: false,
  error: false,
  products: [] as IGenericProduct[],
  productsPriceUpdated: [] as IGenericProduct[],
  productsPriceUpdatedCount: 0,
  product: null as IGenericProduct,
  productCount: 0,
  sortBy: null,
  filters: {
    gender: [],
    category: "All",
    colors: [],
    priceRange: "",
    rating: "",
  },
  checkout: {
    activeStep: 0,
    cart: [] as IGenericProductCart[],
    subtotal: 0,
    total: 0,
    discount: 0,
    shipping: 0,
    billing: null,
    totalIVA: 0,
    totalIEPS: 0,
    totalPriceWithIVAAndIEPS: 0,
  },
};

const slice = createSlice({
  name: "product",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET PRODUCTS
    getProductsSuccess(state, action) {
      state.isLoading = false;
      state.products = action.payload.items;
      state.productCount = action.payload.count;
    },
    getProductSuccessUpdatedPriceProducts(state, action) {
      state.isLoading = false;
      state.productsPriceUpdated = action.payload.items;
      state.productsPriceUpdatedCount = action.payload.count;
    },

    // GET PRODUCT
    getProductSuccess(state, action) {
      state.isLoading = false;
      state.product = action.payload as IGenericProduct;
    },

    //  SORT & FILTER PRODUCTS
    sortByProducts(state, action) {
      state.sortBy = action.payload;
    },

    filterProducts(state, action) {
      state.filters.gender = action.payload.gender;
      state.filters.category = action.payload.category;
      state.filters.colors = action.payload.colors;
      state.filters.priceRange = action.payload.priceRange;
      state.filters.rating = action.payload.rating;
    },

    // CHECKOUT
    getCart(state, action) {
      const cart = action.payload;

      const subtotal = sum(
        cart.map((product) => product.price * product.quantity)
      );
      const discount = cart.length === 0 ? 0 : state.checkout.discount;
      const shipping = cart.length === 0 ? 0 : state.checkout.shipping;
      const billing = cart.length === 0 ? null : state.checkout.billing;

      state.checkout.cart = cart;
      state.checkout.discount = discount;
      state.checkout.shipping = shipping;
      state.checkout.billing = billing;
      state.checkout.subtotal = subtotal;
      state.checkout.total = subtotal - discount;
      let totalIVA = 0;
      state.checkout.cart.forEach(
        (product) =>
          (totalIVA += product.quantity * product.price * product.IVA || 0)
      );
      state.checkout.totalIVA = totalIVA;
      let totalIEPS = 0;
      state.checkout.cart.forEach(
        (product) =>
          (totalIEPS += product.quantity * product.price * product.IEPS || 0)
      );
      state.checkout.totalIEPS = totalIEPS;
      let totalPriceWithIVAAndIEPS = 0;
      state.checkout.cart.forEach(
        (product) =>
          (totalPriceWithIVAAndIEPS +=
            product.quantity * product.totalPriceWithIVAAndIEPS || 0)
      );
      state.checkout.totalPriceWithIVAAndIEPS = totalPriceWithIVAAndIEPS;
    },

    addCart(state, action) {
      const product = action.payload;
      const isEmptyCart = state.checkout.cart.length === 0;
      if (isEmptyCart) {
        state.checkout.cart = [
          ...state.checkout.cart,
          { ...product, quantity: 1 },
        ];
      } else {
        state.checkout.cart = map(state.checkout.cart, (_product) => {
          const isExisted = _product.id === product.id;
          if (isExisted) {
            if (product.availableQuantity > _product.quantity) {
              return {
                ..._product,
                quantity: _product.quantity + 1,
              };
            }
          }
          return _product;
        });
      }
      state.checkout.cart = uniqBy(
        [...state.checkout.cart, { ...product, quantity: 1 }],
        "id"
      );
      let totalIVA = 0;
      state.checkout.cart.forEach(
        (product) =>
          (totalIVA += product.quantity * product.price * product.IVA || 0)
      );
      state.checkout.totalIVA = totalIVA;
      let totalIEPS = 0;
      state.checkout.cart.forEach(
        (product) =>
          (totalIEPS += product.quantity * product.price * product.IEPS || 0)
      );
      state.checkout.totalIEPS = totalIEPS;
      let totalPriceWithIVAAndIEPS = 0;
      state.checkout.cart.forEach((product) => {
        totalPriceWithIVAAndIEPS +=
          product.quantity * product.totalPriceWithIVAAndIEPS;
      });
      state.checkout.totalPriceWithIVAAndIEPS = totalPriceWithIVAAndIEPS;
    },

    deleteCart(state, action) {
      const updateCart = filter(state.checkout.cart, (item) => {
        return item.id !== action.payload;
      });

      state.checkout.cart = updateCart;
    },
    resetCart(state) {
      state.checkout.activeStep = 0;
      state.checkout.cart = [];
      state.checkout.total = 0;
      state.checkout.subtotal = 0;
      state.checkout.discount = 0;
      state.checkout.shipping = 0;
      state.checkout.billing = null;
    },

    onBackStep(state) {
      state.checkout.activeStep -= 1;
    },

    onNextStep(state) {
      state.checkout.activeStep += 1;
    },

    onGotoStep(state, action) {
      const goToStep = action.payload;
      state.checkout.activeStep = goToStep;
    },

    onChangeProductCartField(state, action) {
      const { id, result } = action.payload;
      const updateCart = map(state.checkout.cart, (product) => {
        if (product.id === id) {
          return {
            ...result,
            quantity: product.quantity,
          };
        }
        return product;
      });

      state.checkout.cart = updateCart;
    },

    increaseQuantity(state, action) {
      const productId = action.payload;
      const updateCart = map(state.checkout.cart, (product) => {
        if (product.id === productId) {
          return {
            ...product,
            quantity: product.quantity + 1,
          };
        }
        return product;
      });

      state.checkout.cart = updateCart;
    },


    onChangeQuantity(state, action) {
      const { productId, quantity } = action.payload;
      const updateCart = map(state.checkout.cart, (product) => {
        if (product.id === productId) {
          return {
            ...product,
            quantity: quantity,
          };
        }
        return product;
      });

      state.checkout.cart = updateCart;
    },

    decreaseQuantity(state, action) {
      const productId = action.payload;
      const updateCart = map(state.checkout.cart, (product) => {
        if (product.id === productId) {
          if (product.quantity === 1) {
            return;
          }
          return {
            ...product,
            quantity: product.quantity - 1,
          };
        }
        return product;
      });

      state.checkout.cart = updateCart;
    },

    createBilling(state, action) {
      state.checkout.billing = action.payload;
    },

    applyDiscount(state, action) {
      const discount = action.payload;
      state.checkout.discount = discount;
      state.checkout.total = state.checkout.subtotal - discount;
    },

    applyShipping(state, action) {
      const shipping = action.payload;
      state.checkout.shipping = shipping;
      state.checkout.total =
        state.checkout.subtotal - state.checkout.discount + shipping;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  getCart,
  addCart,
  resetCart,
  onGotoStep,
  onBackStep,
  onNextStep,
  deleteCart,
  createBilling,
  applyShipping,
  applyDiscount,
  onChangeQuantity,
  increaseQuantity,
  decreaseQuantity,
  sortByProducts,
  filterProducts,
} = slice.actions;

function getSearchFilter(search: string): Map<string, any> {
  let filter: any = {};
  if (!!search) {
    filter = {
      $or: [
        { name: { $regex: search, $options: "i" } },
        { product_key: { $regex: search, $options: "i" } },
        { brand: { $regex: search, $options: "i" } },
        { barcode: { $regex: `^${search}`, $options: "i" } },
      ],
    };
  }
  return filter;
}

function getDateFilter(
  dateRange: DateRange,
  dateToLookFor: string
): Map<string, any> {
  if (!dateRange) {
    return;
  }
  let filter = {} as any;
  if (!!dateRange.startDate) {
    filter = {
      ...filter,
      [dateToLookFor]: !!filter?.[dateToLookFor]
        ? { ...filter?.[dateToLookFor], $gte: dateRange.startDate }
        : { $gte: dateRange.startDate },
    };
  }
  if (!!dateRange.endDate) {
    filter = {
      ...filter,
      [dateToLookFor]: !!filter?.[dateToLookFor]
        ? { ...filter?.[dateToLookFor], $lt: dateRange.endDate }
        : { $lt: dateRange.endDate },
    };
  }
  return filter;
}

export const selectQueryString = (filters: IFilter, dateToLookFor: string) => {
  let queryString = "?";
  const filter = {
    ...getSearchFilter(filters.search),
    ...getDateFilter(filters.dateRange, dateToLookFor),

    pharmacy: filters.pharmacy,
  };
  if (filters["stockOnly"]) {
    filter["quantity"] = { $gte: 1 };
  }
  if (filters.brand) {
    filter["brand"] = filters.brand;
  }
  if (filters.supplier) {
    filter["supplier"] = filters.supplier;
  }
  queryString +=
    Object.keys(filter).length > 0 ? `filter=${JSON.stringify(filter)}&` : "";
  queryString += `limit=${filters.pagination.limit}&skip=${filters.pagination.skip}`;
  /*
  if (!!state.sorting.active) {
    queryString += `&sort=${state.sorting.direction === 'asc' ? '' : '-'}${state.sorting.active}`;
  } */
  return queryString;
};

export async function getBaseProductAndCreateItInDB(
  barcode: string
): Promise<IGenericProduct[]> {
  try {
    let baseProduct = await getBaseProductFromBarcode(barcode.trim());

    if (baseProduct.count > 0) {
      if (baseProduct.items[0].barcode === barcode) {
        const newProduct = await createGenericProduct({
          barcode,
          group: baseProduct.items[0].group,
          brand: baseProduct.items[0].brand,
          name: baseProduct.items[0].name,
          price: baseProduct.items[0].price,
          cost: baseProduct.items[0].cost,
          IVA: baseProduct.items[0].IVA,
          IEPS: baseProduct.items[0].IEPS,
          quantity: 0,
          controlled: baseProduct.items[0].controlled,
          product_key: baseProduct.items[0].product_key,
        });
        return [newProduct];
      }
    }

    return [] as IGenericProduct[];
  } catch (err) {
    console.log(err);
    return [] as IGenericProduct[];
  }
}

// ---------------------------------------------------------------------
const validateProductToAddToCart = (
  product: IGenericProduct,
  barcode: string,
  useRegex: boolean
) => {
  if (!product) {
    return false;
  }
  if (useRegex) {
    if (product.barcode.includes(barcode)) return true;
  } else {
    if (product.barcode === barcode) return true;
  }

  return false;
};
export function getProductByBarCodeAndAddToCart(
  barcode: string,
  pharmacy: string,
  useRegex = false
) {
  return async (dispatch): Promise<{ message: string; variant: string }> => {
    if (barcode === "") {
      return;
    }
    try {
      let queryString = "";
      if (useRegex) {
        queryString = `${apiURl}/generic-product-pharmacy-db?barcode=/${barcode}/&pharmacy=${pharmacy}&limit=3`;
      } else {
        queryString = `${apiURl}/generic-product-pharmacy-db?barcode=${barcode}&pharmacy=${pharmacy}&limit=3`;
      }
      const response = await axios.get(queryString);
      let products = response.data.items as IGenericProduct[];

      if (response.data.count === 0) {
        products = await getBaseProductAndCreateItInDB(barcode);
      }
      if (validateProductToAddToCart(products[0], barcode, useRegex)) {
        dispatch(slice.actions.addCart(products[0]));
        return { message: "Producto Agregado", variant: "success" };
      }
      return { message: "Producto No Encontrado", variant: "error" };
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return { message: "Error al buscar", variant: "error" };
    }
  };
}

export function createProductAndAddToCart(
  product: CreateOrUpdateGenericProductDto
) {
  return async (dispatch): Promise<{ message: string; variant: string }> => {
    try {
      const result = await createGenericProduct(product);
      if (validateProductToAddToCart(result, result.barcode, false)) {
        dispatch(slice.actions.addCart(result));
        return { message: "Producto Agregado", variant: "success" };
      }
      return { message: "Producto No Encontrado", variant: "error" };
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return { message: "Error al buscar", variant: "error" };
    }
  };
}

export function addProductsToCart(products: IGenericProduct[]) {
  return async (dispatch): Promise<{ message: string; variant: string }> => {
    try {
      products.forEach((product) => dispatch(slice.actions.addCart(product)));
      return {
        message: products.length + " Products Agregados",
        variant: "success",
      };
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return { message: "Error al buscar", variant: "error" };
    }
  };
}

// ----------------------------------------------------------------------

export function getProducts(query: IFilter) {
  return async (dispatch) => {
    //dispatch(slice.actions.startLoading());
    try {
      const queryString = selectQueryString(query, "createdAt");
      const response = await axios.get(
        `${apiURl}/generic-product-pharmacy-db${queryString}`
      );

      dispatch(slice.actions.getProductsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getProduct(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `${apiURl}/generic-product-pharmacy-db?_id=${id}`
      );
      dispatch(slice.actions.getProductSuccess(response.data.items[0]));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function emptyCart() {
  return async (dispatch) => {
    dispatch(slice.actions.resetCart());
  };
}

// ----------------------------------------------------------------------

export function updateProductCartOriginalValue(
  id: string,
  field: string,
  value: any
) {
  return async (dispatch) => {
    try {
      const result = await updateGenericProduct(id, { [field]: value });
      dispatch(slice.actions.onChangeProductCartField({ id, result }));
    } catch (err) {
      dispatch(slice.actions.hasError(err));
    }
  };
}

// ----------------------------------------------------------------------

export function getProductsUpdatedPrice(query: IFilter) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const queryString = selectQueryString(query, "lastPriceUpdateDate");
      const response = await axios.get(
        `${apiURl}/generic-product-pharmacy-db${queryString}`
      );
      dispatch(
        slice.actions.getProductSuccessUpdatedPriceProducts(response.data)
      );
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
