import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
// import { firestoreReducer } from 'redux-firestore';
// import { firebaseReducer } from 'react-redux-firebase';
import mailReducer from './slices/mail';
import chatReducer from './slices/chat';
import blogReducer from './slices/blog';
import userReducer from './slices/user';
import productReducer from './slices/generic-product';
import supplierReducer from './slices/supplier';
import authJwtReducer from './slices/authJwt';
import settingsReducer from './slices/settings';
import calendarReducer from './slices/calendar';
import notificationsReducer from './slices/notifications';
import statisticsReducer from './slices/statistics'
import purchase from './slices/purchase'
import purchaseProduct from './slices/purchase-product'
import cashRegisterCut from './slices/cashRegisterCut'
import cashRegisterCutClosed from './slices/cashRegisterCutClosed'
import productRestock from './slices/restock-product'
import expenseRestock from './slices/expenses'
import batchReducer from './slices/batch'
import baseProduct from './slices/product'
import clientReduces from './slices/client'
import categoryReducers from "./slices/categories";

import posEventsReduces from "./slices/posEvent";
import orders from "./slices/order";

import newProductReducer from "./slices/new-product";

// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: "root",
  storage: storage,
  keyPrefix: "redux-",
  whitelist: ["settings"],
};

const productPersistConfig = {
  key: "product",
  storage: storage,
  keyPrefix: "redux-",
  whitelist: ["sortBy", "checkout"],
};

const restockProductPersistConfig = {
  key: "restockProduct",
  storage: storage,
  keyPrefix: "redux-",
  whitelist: ["newProducts", "restockProducts"],
};

const newProductPersistConfig = {
  key: "newProduct",
  storage: storage,
  keyPrefix: "redux-",
  whitelist: ["newProducts"],
};

const authPersistConfig = {
  key: "authJwt",
  storage: storage,
  keyPrefix: "redux-",
  whitelist: ["isAuthenticated", "AuthenticatedState"],
};

const purchasesConfig = {
  key: "purchase",
  storage: storage,
  keyPrefix: "redux-",
  whitelist: ["pagination"],
};

const rootReducer = combineReducers({
  // firebase: firebaseReducer,
  // firestore: firestoreReducer,
  mail: mailReducer,
  chat: chatReducer,
  blog: blogReducer,
  user: userReducer,
  category: categoryReducers,
  purchase: persistReducer(purchasesConfig, purchase),
  expense: expenseRestock,
  cashRegisterCutClosed: cashRegisterCutClosed,
  cashRegisterCut: cashRegisterCut,
  settings: settingsReducer,
  calendar: calendarReducer,
  batchs: batchReducer,
  statistics: statisticsReducer,
  notifications: notificationsReducer,
  supplier: supplierReducer,
  purchaseProduct: purchaseProduct,
  baseProduct: baseProduct,
  order: orders,
  posEvent: posEventsReduces,
  newProduct: persistReducer(newProductPersistConfig, newProductReducer),
  restockProduct: persistReducer(restockProductPersistConfig, productRestock),
  product: persistReducer(productPersistConfig, productReducer),
  authJwt: persistReducer(authPersistConfig, authJwtReducer),
  client: clientReduces,
});

export { rootPersistConfig, rootReducer };
  
export type IRootState = ReturnType<typeof rootReducer>;
