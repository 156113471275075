import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import 'lazysizes';
// import 'src/_api_';
import './utils/i18n';
import './utils/highlight';
import 'simplebar/src/simplebar.css';
import 'mapbox-gl/dist/mapbox-gl.css';
import 'slick-carousel/slick/slick.css';
import 'react-image-lightbox/style.css';
import 'react-quill/dist/quill.snow.css';
import 'slick-carousel/slick/slick-theme.css';
import 'lazysizes/plugins/attrchange/ls.attrchange';
import 'lazysizes/plugins/object-fit/ls.object-fit';
import 'lazysizes/plugins/parent-fit/ls.parent-fit';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import reportWebVitals from './reportWebVitals';
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";


//import { AuthProvider } from './contexts/auth/auth.provider';
if (process.env.NODE_ENV === "production") {
  Sentry.init({
    dsn: "https://a42c186b35e24f9d9ac50f212b90c8f0@o982253.ingest.sentry.io/5937249",
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
  });
}


ReactDOM.render(
  // <React.StrictMode>
  //   <AuthProvider>
  //     <App />
  //   </AuthProvider>
  // </React.StrictMode>,
  // <AuthProvider>
  <App />,
  // </AuthProvider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
