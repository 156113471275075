

import { createSlice } from '@reduxjs/toolkit';
import { DateRange } from './generic-product';
import { IFilter } from 'redux/shared/interfaces';
import { getPurchasesFromBack } from '../../lib/api/cash-register-purchase'
import { IGenericProduct } from 'lib/interfaces/generic-product';
import { ICashRegisterPurchase } from 'lib/interfaces/cash-register-purchase';
// ----------------------------------------------------------------------

export enum PaymentMethodTypeEnum {
    Stripe = 'Stripe',
    Cash = 'Cash',
    AmericanExpress = 'American Express',
    CreditCard = 'Credit Card',
    DebitCard = 'Debit Card',
}

export enum DeliveryTypeEnum {
    InStore = 'InStore',
    Delivery = 'Delivery'
}
export interface IPurchaseProduct {

    name: string;
    barcode: string;
    quantity: number;
    price: number;
    reference: IGenericProduct;

}
export interface IPurchase {
    id?: string;
    total?: number;
    referenceNo?: string;
    paymentMethod: PaymentMethodTypeEnum;
    pharmacy: string;
    deliveryType: DeliveryTypeEnum;
    cashier: {
        reference: string;
        fullName: string;
    }
    // cashRegister: {
    //     number: string;
    //     reference: string;
    // }
    client: {
        reference: string;
        fullName: string;
    }
    invoice?: string;
    comissionPercentage?: number;
    products: IPurchaseProduct[];
    createdAt: Date;
    updatedAt?: Date;
}

const initialState = {
    purchases: [] as ICashRegisterPurchase[],
    purchase: {} as ICashRegisterPurchase,
    error: false,
    isLoading: false,
    purchaseCount: 0,
    sortBy: null,
    pagination: {
        page: 0,
        rowsPerPage: 25,
    },
    filters: {
        gender: [],
        category: 'All',
        colors: [],
        priceRange: '',
        rating: '',
    },
};


const slice = createSlice({
    name: 'purchase',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },

        // GET PURCHASES
        getPuchasesSuccess(state, action) {
            state.isLoading = false;
            state.purchases = action.payload.items;
            state.purchaseCount = action.payload.count;
        },
        // GET PURCHASES
        getPuchaseSuccess(state, action) {
            state.isLoading = false;
            state.purchase = action.payload;

        },
        udpatePagination(state, action) {
            state.pagination.page = action.payload.page;
            state.pagination.rowsPerPage = action.payload.rowsPerPage
        },
        //  SORT & FILTER PRODUCTS
        sortByPurchases(state, action) {
            state.sortBy = action.payload;
        },

        //  SORT & FILTER PRODUCTS
        cleanPurchase(state) {
            state.purchase = {} as ICashRegisterPurchase;
        },

        filterProducts(state, action) {
            state.filters.gender = action.payload.gender;
            state.filters.category = action.payload.category;
            state.filters.colors = action.payload.colors;
            state.filters.priceRange = action.payload.priceRange;
            state.filters.rating = action.payload.rating;
        },
    },
});

// Reducer
export default slice.reducer;

// Actions
export const {
    filterProducts,
} = slice.actions;


function getSearchFilter(search: string): Map<string, any> {
    let filter: any = {};
    if (!!search) {
        filter = {
            $or: [{ ticketNumber: search }],
        };
    }
    return filter;
}

function getDateFilter(dateRange: DateRange): Map<string, any> {
    if (!dateRange) {
        return
    }
    let filter = {} as any;
    if (!!dateRange.startDate) {
        filter = {
            ...filter,
            createdAt: !!filter?.createdAt
                ? { ...filter?.createdAt, $gte: dateRange.startDate }
                : { $gte: dateRange.startDate },
        };
    }
    if (!!dateRange.endDate) {
        filter = {
            ...filter,
            createdAt: !!filter?.createdAt ? { ...filter?.createdAt, $lt: dateRange.endDate } : { $lt: dateRange.endDate },
        };
    }
    return filter;
}

export const selectQueryString = (filters: IFilter) => {
    let queryString = '?';
    const filter = {
        ...getSearchFilter(filters.search),
        ...getDateFilter(filters.dateRange),
        pharmacy: filters.pharmacy
    };
    queryString += Object.keys(filter).length > 0 ? `filter=${JSON.stringify(filter)}&` : '';
    queryString += `limit=${filters.pagination.limit}&skip=${filters.pagination.skip}`;
    if (filters['onlyWithRecepie']) {
        queryString += '&needsPrescription=true'
    }
    /*
    if (!!state.sorting.active) {
      queryString += `&sort=${state.sorting.direction === 'asc' ? '' : '-'}${state.sorting.active}`;
    } */
    return queryString;
};

export const updatePagination = ({ page, rowsPerPage }) => {
    return dispatch => {
        dispatch(slice.actions.udpatePagination({ page, rowsPerPage}))
    }
}


// ----------------------------------------------------------------------

export function getPurchases(query: IFilter) {
    return async (dispatch) => {
        //dispatch(slice.actions.startLoading());
        try {
            const queryString = selectQueryString(query)
            const response = await getPurchasesFromBack(queryString)
            dispatch(slice.actions.getPuchasesSuccess(response));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function getPurchase(query: IFilter) {
    return async (dispatch) => {
        dispatch(slice.actions.startLoading());
        try {

            const queryString = selectQueryString(query)
            const response = await getPurchasesFromBack(queryString)
            dispatch(slice.actions.getPuchaseSuccess(response.items[0]));
        } catch (error) {
            console.error(error);
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function cleanPurchaseObj() {
    return async (dispatch) => {
        dispatch(slice.actions.cleanPurchase());

    };
}


export function getPurchaseById(id) {
    return async (dispatch) => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await getPurchasesFromBack(`?_id=${id}`)
            dispatch(slice.actions.getPuchaseSuccess(response.items[0]));
        } catch (error) {
            console.error(error);
            dispatch(slice.actions.hasError(error));
        }
    };
}


