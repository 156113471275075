import rtl from 'jss-rtl';
import { create } from 'jss';
import palette from './palette';
import shadows from './shadows';
// import PropTypes from 'prop-types';
import typography from './typography';
import breakpoints from './breakpoints';
import createCache from '@emotion/cache';
import rtlPlugin from 'stylis-plugin-rtl';
import GlobalStyles from './globalStyles';
import borderRadius from './borderRadius';
import componentsOverride from './overrides';
import { CacheProvider } from '@emotion/react';
import useSettings from '../../hooks/useSettings';
import React, { useMemo, useEffect } from 'react';
import {
  jssPreset,
  ThemeProvider,
  createMuiTheme,
  StylesProvider,
} from '@material-ui/core/styles';
import { CssBaseline, ThemeOptions } from '@material-ui/core';
import { esES } from '@material-ui/core/locale';
// ----------------------------------------------------------------------

// RTLProvider.propTypes = {
//   direction: PropTypes.oneOf(['ltr', 'rtl']),
//   children: PropTypes.node,
// };

export interface IRTLProviderProps {
  direction: 'ltr' | 'rtl';
  children: React.ReactNode;
}

function RTLProvider({ direction, children }: IRTLProviderProps) {
  const isRTL = direction === 'rtl';
  const jss = create({
    plugins: [...jssPreset().plugins, rtl()],
  });

  const cache = createCache({
    key: isRTL ? 'rtl' : 'css',
    prepend: true,
    stylisPlugins: isRTL ? [rtlPlugin] : [],
  } as any);

  cache.compat = true;

  useEffect(() => {
    document.dir = direction;
  }, [direction]);

  return (
    <CacheProvider value={cache}>
      <StylesProvider jss={jss}>{children}</StylesProvider>
    </CacheProvider>
  );
}

export interface IThemeConfigProps {
  children: React.ReactNode;
}

// ThemeConfig.propTypes = {
//   children: PropTypes.node,
// };

function ThemeConfig({ children }: IThemeConfigProps) {
  const { themeMode, themeDirection } = useSettings();
  const isLight = themeMode === 'light';

  const themeOptions: ThemeOptions = (useMemo(
    () => ({
      palette: palette[isLight ? 'light' : 'dark'],
      shadows: shadows[isLight ? 'light' : 'dark'],
      typography: typography,
      shape: borderRadius,
      breakpoints: breakpoints,
      direction: themeDirection,
      components: componentsOverride({
        theme: {
          palette: palette[isLight ? 'light' : 'dark'],
          shadows: shadows[isLight ? 'light' : 'dark'],
          typography: typography,
          shape: borderRadius,
          direction: themeDirection,
        },
      }),
      esES,
    }),
    [isLight, themeDirection]
  ) as unknown) as ThemeOptions;

  const theme = createMuiTheme(themeOptions);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <GlobalStyles />
      <RTLProvider direction={themeDirection === "ltr" ? "ltr" : "rtl"}>
        {children}
      </RTLProvider>
    </ThemeProvider>
  );
}

export default ThemeConfig;
