import axios, { AxiosResponse } from "axios";
import { IProduct } from "lib/interfaces/product";
const apiURL = process.env.REACT_APP_API_URL;
axios.interceptors.request.use(
  function (config) {
    config.headers.Authorization = `Bearer ${localStorage.getItem(
      process.env.REACT_APP_ACCESS_TOKEN || "medify_access_token"
    )}`;
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

interface GetProductResponse {
  items: IProduct[];
  count: number;
}
export const getBaseProductFromBarcode = async (
  barcode: string
): Promise<GetProductResponse> => {
  try {
    if (barcode === "" || barcode === undefined) {
      return {
        items: [],
        count: 0,
      };
    }
    const result: AxiosResponse = await axios.get(
      `${apiURL}/product?barcode=${barcode}&limit=3`
    );
    const data: GetProductResponse = result.data;
    return data;
  } catch (err) {
    console.log(err);
    throw new Error(err);
  }
};
