import { PaymentMethodTypeEnum } from "redux/slices/purchase";
import axios, { AxiosResponse } from 'axios';
import { ICashRegisterPurchase } from "lib/interfaces/cash-register-purchase";
import { taxesValidator } from './generic-product'
import { IKPI } from "lib/interfaces/kpi";
const apiURL = process.env.REACT_APP_API_URL;
axios.interceptors.request.use(function (config) {
    config.headers.Authorization = `Bearer ${localStorage.getItem(process.env.REACT_APP_ACCESS_TOKEN || 'medify_access_token')}`;
    return config;
}, function (error) {
    // Do something with request error
    return Promise.reject(error);
});

export interface CreateCashRegisterPurchaseProductDto {
    name: string;
    barcode: string;
    quantity: number;
    price: number;
    totalPriceWithIVAAndIEPS: number;
    cost: number;
    IVA: number;
    IEPS: number;
    discount: number;
    product_key: string;
    reference: string;
    supplier?: string;
}

export interface CreateCashRegisterPurchaseClient {
    reference: string;
    fullName: string;
}

export interface CreateCashRegisterPurchaseCashRegister {
    reference: string;
    number: number;
}
export interface CreateCashRegisterPurchaseDto {
    paymentMethodType: PaymentMethodTypeEnum;
    deliveryType?: any;
    receivedAmount: number;
    changeAmount: number;
    //    cashRegister: CreateCashRegisterPurchaseCashRegister;
    client?: CreateCashRegisterPurchaseClient;
    paymentMethodPayload?: any;
    pharmacy: string;
    doctorsName?: string;
    doctorsCedula?: string;
    doctorsAddress?: string;
    needsPrescription?: boolean;
    prescriptionNumber?: string;
    products: CreateCashRegisterPurchaseProductDto[];
}

export const createPurchaseInBack = async (dto: CreateCashRegisterPurchaseDto, accessToken: string): Promise<ICashRegisterPurchase> => {
    try {
        dto.products.forEach(product => {
            taxesValidator(product)
        })
        const result: void | AxiosResponse = await axios.post(`${apiURL}/cash-register-purchases`, dto, { headers: { 'Authorization': `Bearer ${accessToken}` } })
            .catch((err: any) => {
                if (err?.response?.data?.errorCode === 12) {
                    throw new Error('Error en código de SAT de producto')
                }
            })
        const data: ICashRegisterPurchase = (result as AxiosResponse).data;
        return data
    } catch (err) {
        console.log(err)
        throw new Error(err.message)
    }

}

export interface ICashierRegisterResponse {
    items: ICashRegisterPurchase[];
    count: number;
}

export const getPurchasesFromBack = async (queryString: string): Promise<ICashierRegisterResponse> => {
    try {
        const result: AxiosResponse = await axios.get(`${apiURL}/cash-register-purchases${queryString}&populate=account&sort=-createdAt`)
        const data: ICashierRegisterResponse = result.data;
        return data
    } catch (err) {
        console.log(err)
        throw new Error(err)
    }

}

export const createRefundPurchase = async (id: string, accessToken: string): Promise<ICashRegisterPurchase> => {
    try {
        const result: AxiosResponse = await axios.delete(`${apiURL}/cash-register-purchases/${id}`)
        const data: ICashRegisterPurchase = result.data;
        return data
    } catch (err) {
        console.log(err)
        throw new Error(err)
    }

}

export const getPurchasesResultFromBack = async (startDate: Date, endDate: Date): Promise<IKPI> => {
    try {
        const result: void | AxiosResponse = await axios.get(`${apiURL}/cash-register-purchases/kpi/aggregated-results?startDate=${startDate}&endDate=${endDate}`).catch((err: any) => {
            if (err?.response?.data?.errorCode === 3) {
                throw new Error('No tienes privilegios')
            }
        })
        const data: IKPI = (result as any).data;
        return data
    } catch (err) {
        console.log(err)
        throw new Error(err)
    }
}