import axios, { AxiosResponse } from 'axios';
import { ISupplier, SupplierPaymentTypes, SupplierDaysToRestock } from "lib/interfaces/supplier"
const apiURL = process.env.REACT_APP_API_URL;
const accessToken = localStorage.getItem(process.env.REACT_APP_ACCESS_TOKEN || 'medify_access_token')

export interface CreateSupplierDto {
    name: string;
    email: string;
    phone: string;
    dateToRestock: SupplierDaysToRestock[];
    paymentType: SupplierPaymentTypes;
    represtantiveName: string;
}

export interface EditSupplierDto {
    name?: string;
    email?: string;
    phone?: string;
    dateToRestock: SupplierDaysToRestock[];
    paymentType?: SupplierPaymentTypes;
    represtantiveName?: string;
}



// export const createPurchaseInBack = async (dto: CreateCashRegisterPurchaseDto, accessToken: string): Promise<ICashRegisterPurchase> => {
//     try {
//         const result: AxiosResponse = await axios.post(`${apiURL}/cash-register-purchases`, dto, { headers: { 'Authorization': `Bearer ${accessToken}` } })
//         const data: ICashRegisterPurchase = result.data;
//         return data
//     } catch (err) {
//         console.log(err)
//         throw new Error(err)
//     }

// }

export interface ISupplierResponse {
    items: ISupplier[];
    count: number;
}

export const getSuppliersFromBack = async (queryString: string): Promise<ISupplierResponse> => {
    try {
        const result: AxiosResponse = await axios.get(`${apiURL}/supplier${queryString}&sort=-createdAt`, { headers: { 'Authorization': `Bearer ${accessToken}` } })
        const data: ISupplierResponse = result.data;
        return data
    } catch (err) {
        console.log(err)
        throw new Error(err)
    }

}

export const editSupplier = async (id: string, dto: EditSupplierDto, accessToken: string): Promise<ISupplier> => {
    try {
        const result: AxiosResponse = await axios.put(`${apiURL}/supplier/${id}`, dto, { headers: { 'Authorization': `Bearer ${accessToken}` } })
        const data: ISupplier = result.data;
        return data
    } catch (err) {
        console.log(err)
        throw new Error(err)
    }

}

export const createSupplier = async (dto: CreateSupplierDto, accessToken: string): Promise<ISupplier> => {
    try {
        const result: AxiosResponse = await axios.post(`${apiURL}/supplier`, dto, { headers: { 'Authorization': `Bearer ${accessToken}` } })
        const data: ISupplier = result.data;
        return data
    } catch (err) {
        console.log(err)
        throw new Error(err)
    }

}

export const deleteSupplier = async (id: string): Promise<ISupplier> => {
    try {
        const result: AxiosResponse = await axios.delete(`${apiURL}/supplier/${id}`,{ headers: { 'Authorization': `Bearer ${accessToken}` } })
        const data: ISupplier = result.data;
        return data
    } catch (err) {
        console.log(err)
        throw new Error(err)
    }

}