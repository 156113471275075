import { createSlice } from "@reduxjs/toolkit";
import { CreateGenericProductDto } from "lib/api/generic-product";
import faker from "faker";
import { CreateBatchDto } from "lib/api/batch";
import { getBaseProductFromBarcode } from "lib/api/product";
import { IProduct } from "lib/interfaces/product";

// ----------------------------------------------------------------------

export interface DateRange {
  startDate: Date;
  endDate: Date;
}

export interface RestockBatch extends CreateBatchDto {
  id?: string;
}

const initialState = {
  isLoading: false,
  error: false,
  addToExpenses: false,
  supplier: "",
  externalInvoice: "",
  externalOrderId: "",
  selectedBatches: [] as RestockBatch[],
  newProducts: [] as CreateOrUpdateGenericProductDto[],
  product: null as CreateOrUpdateGenericProductDto,
  baseProduct: null as IProduct,
  productsToLookFor: [] as CreateGenericProductDto[],
  productCount: 0,
  sortBy: null,
  filters: {
    gender: [],
    category: "All",
    colors: [],
    priceRange: "",
    rating: "",
  },
};

const slice = createSlice({
  name: "newProduct",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },
    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    saveProductsToLookFor(state, action) {
      state.productsToLookFor = action.payload;
    },
    updateAddToExpenses(state, action) {
      state.addToExpenses = action.payload;
    },
    updateExternalInvoice(state, action) {
      state.externalInvoice = action.payload;
    },
    updateExternalOrderId(state, action) {
      state.externalOrderId = action.payload;
    },
    updateSupplier(state, action) {
      state.supplier = action.payload;
    },
    deleteProducts(state, action) {
      const idsToDelete = action.payload;
      state.newProducts = state.newProducts.filter(
        (product) => !idsToDelete.includes((product as any).id)
      );
    },

    // GET PRODUCTS
    getProductsSuccess(state, action) {
      const products = action.payload.products;
      let dbProducts = action.payload.dbProducts.items;

      dbProducts = dbProducts.map((item) => {
        return {
          ...item,
          batches: [],
        };
      });
      products.forEach((product) => {
        const objIndex = dbProducts.findIndex(
          (obj) => obj.barcode === product.barcode
        );

        if (objIndex === -1) {
          state.newProducts = [
            ...state.newProducts,
            {
              ...product,
              id: faker.random.uuid(),
              batches: [],
              batch: false,
              totalPriceWithIVAAndIEPS:
                product.price + product.IVA + product.IEPS,
            },
          ];
        }
      });
      state.isLoading = false;
    },

    updateProduct(state, action) {
      state.isLoading = false;
      const id = action.payload.id;
      const featureToEdit = action.payload.field;
      const newValue = action.payload.value;
      const newProductIndex = state.newProducts.findIndex(
        (obj) => (obj as any).id === id
      );
      if (newProductIndex !== -1) {
        let productToEdit = state.newProducts[newProductIndex];
        productToEdit[featureToEdit] = newValue;
        state.newProducts = [
          ...state.newProducts.slice(0, newProductIndex),
          productToEdit,
          ...state.newProducts.slice(newProductIndex + 1),
        ];
      }
    },
    //  SORT & FILTER PRODUCTS
    sortByProducts(state, action) {
      state.sortBy = action.payload;
    },
    saveProductsInBack(state) {
      state.newProducts = [];
    },

    filterProducts(state, action) {
      state.filters.gender = action.payload.gender;
      state.filters.category = action.payload.category;
      state.filters.colors = action.payload.colors;
      state.filters.priceRange = action.payload.priceRange;
      state.filters.rating = action.payload.rating;
    },
    saveBaseProduct(state, action) {
      const products = action.payload as IProduct[];
      if (products.length === 0) {
        state.baseProduct = null;
      } else {
        state.baseProduct = products[0];
      }
    },
    resetAll(state) {
      state.newProducts = [];
      state.addToExpenses = false;
      state.supplier = null;
      state.externalInvoice = "";
      state.externalOrderId = "";
    },
    getProductBatches(state, action) {
      let id = action.payload;
      const newProductIndex = state.newProducts.findIndex(
        (obj) => obj.id === id
      );
      if (newProductIndex !== -1) {
        state.selectedBatches = [...state.newProducts[newProductIndex].batches];
      }
    },
    updateProductBatches(state, action) {
      let { index, field, value } = action.payload;
      const batchToEdit = state.selectedBatches[index];
      batchToEdit[field] = value;
      state.selectedBatches = [
        ...state.selectedBatches.slice(0, index),
        batchToEdit,
        ...state.selectedBatches.slice(index + 1),
      ];
    },
    updateAllProductBatches(state, action) {
      let batches = action.payload;
      state.selectedBatches = [...batches];
    },
    saveProductInGrid(state, action) {
      state.newProducts = [...state.newProducts, action.payload];
    },
    saveProductBatches(state, action) {
      let id = action.payload;
      let newQuantity = 0;
      state.selectedBatches.forEach((batch) => (newQuantity += batch.quantity));
      const newProductIndex = state.newProducts.findIndex(
        (obj) => (obj as any).id === id
      );
      if (newProductIndex !== -1) {
        let productToEdit = state.newProducts[newProductIndex];
        productToEdit.quantity = newQuantity;
        productToEdit.batches = state.selectedBatches;
        productToEdit.batch = true;
        state.newProducts = [
          ...state.newProducts.slice(0, newProductIndex),
          { ...productToEdit },
          ...state.newProducts.slice(newProductIndex + 1),
        ];
      }

      state.selectedBatches = [];
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { sortByProducts, filterProducts } = slice.actions;

export const selectQueryString = (products: CreateGenericProductDto[]) => {
  const barcodes = products.map((product) => product.barcode);
  return "?barcode=" + barcodes.join();
};

// ---------------------------------------------------------------------

export function updateProductsFromGrid(product: any) {
  return (dispatch) => {
    try {
      dispatch(slice.actions.updateProduct(product));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function searchBaseProduct(barcode: string) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      if (barcode === "") {
        return;
      }
      const response = await getBaseProductFromBarcode(barcode);
      dispatch(slice.actions.saveBaseProduct(response.items));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export interface CreateOrUpdateGenericProductDto
  extends CreateGenericProductDto {
  id: string;
  batches: RestockBatch[];
  isBillable: boolean;
}

// ----------------------------------------------------------------------

export function emptyTable() {
  return async (dispatch) => {
    dispatch(slice.actions.resetAll());
  };
}

// ----------------------------------------------------------------------

export function deleteProducts(ids: string[]) {
  return (dispatch) => {
    //dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.deleteProducts(ids));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function saveProductInGrid(product: CreateOrUpdateGenericProductDto) {
  return (dispatch) => {
    try {
      dispatch(slice.actions.saveProductInGrid(product));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getProductBatches(id: string) {
  return (dispatch) => {
    //dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.getProductBatches(id));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function updateProductBatches({ index, field, value }) {
  return (dispatch) => {
    //dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.getProductBatches({ index, field, value }));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
// ----------------------------------------------------------------------

export function saveProductBatches(id) {
  return (dispatch) => {
    //dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.saveProductBatches(id));
    } catch (error) {
      console.log("errrrr", error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function updateAllProductBatches(newBatches: RestockBatch) {
  return (dispatch) => {
    //dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.updateAllProductBatches(newBatches));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
// ---------------------------------------------------------------------
