import clsx from 'clsx';
import React, { forwardRef } from 'react';
import { ButtonAnimate } from '../../../components/Animate';
import { makeStyles, alpha } from '@material-ui/core/styles';
import { capitalize } from '@material-ui/core/utils';
import { IconButton } from '@material-ui/core';

// ----------------------------------------------------------------------

const useStyles = makeStyles((theme) => {
  const createStyle = (color) => {
    return {
      color: theme.palette[color].main,
      '&:hover': {
        backgroundColor: alpha(
          theme.palette[color].main,
          theme.palette.action.hoverOpacity
        ),
      },
    };
  };

  return {
    colorInfo: createStyle('info'),
    colorSuccess: createStyle('success'),
    colorWarning: createStyle('warning'),
    colorError: createStyle('error'),
    colorWhite: {
      color: theme.palette.common.white,
      '&:hover': {
        backgroundColor: alpha(
          theme.palette.common.white,
          theme.palette.action.hoverOpacity
        ),
      },
    },
  };
});

// ----------------------------------------------------------------------

const MIconButton = forwardRef(
  (
    { color = 'default', children, className, ...other }: IMIconButtonProps,
    ref: any
  ) => {
    const classes = useStyles();

    if (
      color === 'default' ||
      color === 'inherit' ||
      color === 'primary' ||
      color === 'secondary'
    ) {
      return (
        <ButtonAnimate>
          <IconButton ref={ref} color={color} className={className} {...other}>
            {children}
          </IconButton>
        </ButtonAnimate>
      );
    }

    return (
      <ButtonAnimate>
        <IconButton
          ref={ref}
          className={clsx(
            {
              [classes[`color${capitalize(color)}`]]:
                (color as any) !== 'default',
            },
            className
          )}
          {...other}
        >
          {children}
        </IconButton>
      </ButtonAnimate>
    );
  }
);

//TODO: Proper types for any override

export interface IMIconButtonProps {
  children?: React.ReactNode;
  classes?: any;
  className?: string;
  size?: any;
  onClick?: any;
  color?:
    | 'default'
    | 'inherit'
    | 'primary'
    | 'secondary'
    | 'info'
    | 'success'
    | 'warning'
    | 'error'
    | 'white';
  [key: string]: any;
}

export default MIconButton;
