import clsx from 'clsx';
import React from 'react';
// import PropTypes from 'prop-types';
import { varFadeInUp } from './';
import { motion, AnimatePresence } from 'framer-motion';
import { makeStyles } from '@material-ui/core/styles';
import { Dialog } from '@material-ui/core';

// ----------------------------------------------------------------------

const useStyles = makeStyles((theme: any) => ({
  root: {},
  paper: {
    borderRadius: theme.shape.borderRadiusMd,
    backgroundColor: theme.palette.background.paper,
  },
}));
export interface IDialogAnimateProps {
  open: boolean;
  onClose?: (event: {}, reason: 'backdropClick' | 'escapeKeyDown') => void;
  chilren?: React.ReactNode;
  className?: string;
  [key: string]: any;
}
// ----------------------------------------------------------------------

// DialogAnimate.propTypes = {
//   open: PropTypes.bool.isRequired,
//   onClose: PropTypes.func,
//   children: PropTypes.node.isRequired,
//   className: PropTypes.string,
// };

function DialogAnimate({
  open = false,
  onClose,
  children,
  className,
  ...other
}: IDialogAnimateProps) {
  const classes = useStyles();

  return (
    <AnimatePresence>
      {open && (
        <Dialog
          fullWidth
          maxWidth="xs"
          open={open}
          onClose={onClose}
          classes={{ paper: classes.paper }}
          className={clsx(classes.root, className)}
          {...({
            PaperComponent: motion.div,
            PaperProps: { ...varFadeInUp },
          } as any)}
          {...other}
        >
          {children}
        </Dialog>
      )}
    </AnimatePresence>
  );
}

export default DialogAnimate;
