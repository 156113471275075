import clsx from 'clsx';
import React from 'react';
import { makeStyles, alpha } from '@material-ui/core/styles';
import { capitalize } from '@material-ui/core/utils';
import { Radio } from '@material-ui/core';

// ----------------------------------------------------------------------

const useStyles = makeStyles((theme) => {
  const createStyle = (color) => {
    return {
      '&.Mui-checked': {
        color: theme.palette[color].main,
      },
      '&:hover, &.Mui-checked:hover': {
        backgroundColor: alpha(
          theme.palette[color].main,
          theme.palette.action.hoverOpacity
        ),
      },
    };
  };

  return {
    colorInfo: createStyle('info'),
    colorSuccess: createStyle('success'),
    colorWarning: createStyle('warning'),
    colorError: createStyle('error'),
  };
});

// ----------------------------------------------------------------------

function MRadio({ color = 'primary', className, ...other }: IMRadioProps) {
  const classes = useStyles();

  if (color === 'default' || color === 'primary' || color === 'secondary') {
    return <Radio color={color} className={className} {...other} />;
  }

  return (
    <Radio
      className={clsx(
        {
          [classes[`color${capitalize(color)}`]]: color,
        },
        className
      )}
      {...other}
    />
  );
}
export interface IMRadioProps {
  classes: any;
  className?: string;
  color:
    | 'default'
    | 'primary'
    | 'secondary'
    | 'info'
    | 'success'
    | 'warning'
    | 'error';
}

export default MRadio;
