import { createSlice } from '@reduxjs/toolkit';
import { IProduct } from 'lib/interfaces/product';
import { getBaseProductFromBarcode } from 'lib/api/product'

// ----------------------------------------------------------------------


export interface DateRange {
  startDate: Date;
  endDate: Date;
}

const initialState = {
  isLoading: false,
  error: false,
  productsPriceUpdated: [] as IProduct[],
  products: [] as IProduct[],
  product: null as IProduct,
  productsPriceUpdatedCount: 0,
  productCount: 0,
  sortBy: null,
  filters: {
    gender: [],
    category: 'All',
    colors: [],
    priceRange: '',
    rating: '',
  },
};

const slice = createSlice({
  name: 'baseProduct',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET PRODUCTS
    getProductsSuccess(state, action) {
      state.isLoading = false;
      state.products = action.payload.items;
      state.productCount = action.payload.count;
    },

    // GET PRODUCT
    getProductSuccess(state, action) {
      state.isLoading = false;
      state.product = action.payload as IProduct;
    },
    // GET PRODUCT
    resetProduct(state) {
      state.product = null
    },

    //  SORT & FILTER PRODUCTS
    sortByProducts(state, action) {
      state.sortBy = action.payload;
    },

    filterProducts(state, action) {
      state.filters.gender = action.payload.gender;
      state.filters.category = action.payload.category;
      state.filters.colors = action.payload.colors;
      state.filters.priceRange = action.payload.priceRange;
      state.filters.rating = action.payload.rating;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  sortByProducts,
  filterProducts,
} = slice.actions;



// ----------------------------------------------------------------------

export function getProductFromBarcode(barcode: string) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await getBaseProductFromBarcode(barcode)
      dispatch(slice.actions.getProductSuccess(response.items[0]));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}


// ----------------------------------------------------------------------

export function resetProduct() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.resetProduct());
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}


