import axios, { AxiosResponse } from 'axios';
import { ICashierRegisterCut } from 'lib/interfaces/cash-register-cut';
import { ICashRegisterPurchase } from "lib/interfaces/cash-register-purchase";
import { DateRange } from 'redux/slices/generic-product';
import { IFilter } from 'redux/shared/interfaces';
const apiURL = process.env.REACT_APP_API_URL;

axios.interceptors.request.use(function (config) {
    config.headers.Authorization = `Bearer ${localStorage.getItem(process.env.REACT_APP_ACCESS_TOKEN || 'medify_access_token')}`;
    return config;
}, function (error) {
    // Do something with request error
    return Promise.reject(error);
});

export interface IAvailableCashRegisterPruchaseResponse {
    items: ICashRegisterPurchase[];
    count: number;
}

export const getAvailableCashRegisterPurchase = async (accessToken: string): Promise<IAvailableCashRegisterPruchaseResponse> => {
    try {
        const result: void | AxiosResponse = await axios.get(`${apiURL}/cash-register-cuts/available-purchases`, { headers: { 'Authorization': `Bearer ${accessToken}` } }).catch((err: any) => {
            if (err?.response?.data?.errorCode === 3) {
                throw new Error('No tienes privilegios')
            }
        })
        const data: IAvailableCashRegisterPruchaseResponse = (result as any).data;
        return data
    } catch (err) {
        console.log(err)
        throw new Error(err)
    }

}

export interface ICashRegisterCutResponse {
    items: ICashierRegisterCut[];
    count: number;
}


function getSearchFilter(search: string): Map<string, any> {
    let filter: any = {};
    if (!!search) {
        filter = {
            $or: [{ name: { $regex: search, $options: 'i' } }, { brand: { $regex: search, $options: 'i' } }, { barcode: { $regex: search, $options: 'i' } }],
        };
    }
    return filter;
}

function getDateFilter(dateRange: DateRange): Map<string, any> {
    if (!dateRange) {
        return
    }
    let filter = {} as any;
    if (!!dateRange.startDate) {
        filter = {
            ...filter,
            createdAt: !!filter?.createdAt
                ? { ...filter?.createdAt, $gte: dateRange.startDate }
                : { $gte: dateRange.startDate },
        };
    }
    if (!!dateRange.endDate) {
        filter = {
            ...filter,
            createdAt: !!filter?.createdAt ? { ...filter?.createdAt, $lt: dateRange.endDate } : { $lt: dateRange.endDate },
        };
    }
    return filter;
}

export const selectQueryString = (filters: IFilter) => {
    let queryString = '?';
    const filter = {
        ...getSearchFilter(filters.search),
        ...getDateFilter(filters.dateRange),
        pharmacy: filters.pharmacy
    };
    queryString += Object.keys(filter).length > 0 ? `filter=${JSON.stringify(filter)}&` : '';
    queryString += `limit=${filters.pagination.limit}&skip=${filters.pagination.skip}`;
    /*
    if (!!state.sorting.active) {
      queryString += `&sort=${state.sorting.direction === 'asc' ? '' : '-'}${state.sorting.active}`;
    } */
    return queryString;
};


export const getCashRegisterCutsFromBack = async (query: IFilter): Promise<ICashRegisterCutResponse> => {
    try {
        const queryString = selectQueryString(query)
        const result: void | AxiosResponse = await axios.get(`${apiURL}/cash-register-cuts${queryString}&populate=purchases,expenses,refunds&sort=-createdAt`).catch((err: any) => {
            if (err?.response?.data?.errorCode === 3) {
                throw new Error('No tienes privilegios')
            }
        })
        const data: ICashRegisterCutResponse = (result as any).data;
        return data
    } catch (err) {
        console.log(err)
        throw new Error(err)
    }

}

export interface CreateCashRegisterCut {
    initialAmount: number;
    date: Date;
    purchases: string[];
    refunds: string[];
    expenses: string[];
    // cashRegister: string;
    from: Date;
    to: Date;
}

export const createCashRegisterCutInBack = async (body: CreateCashRegisterCut, accessToken: string): Promise<IAvailableCashRegisterPruchaseResponse> => {
    try {
        const result: void | AxiosResponse = await axios.post(`${apiURL}/cash-register-cuts`, body, { headers: { 'Authorization': `Bearer ${accessToken}` } }).catch((err: any) => {
            if (err?.response?.data?.errorCode === 3) {
                throw new Error('No tienes privilegios')
            }
        })
        const data: IAvailableCashRegisterPruchaseResponse = (result as any).data;
        return data
    } catch (err) {
        console.log(err)
        throw new Error(err)
    }

}