import axios, { AxiosResponse } from 'axios';
import { IBatch } from 'lib/interfaces/batch';
const apiURL = process.env.REACT_APP_API_URL;
axios.interceptors.request.use(function (config) {
    config.headers.Authorization = `Bearer ${localStorage.getItem(process.env.REACT_APP_ACCESS_TOKEN || 'medify_access_token')}`;
    return config;
}, function (error) {
    // Do something with request error
    return Promise.reject(error);
});

export interface CreateBatchDto {
    batchNo: string;
    product: string;
    barcode: string;
    quantity: number;
    creationDate?: Date;
    expiryDate?: Date;
}

export interface EditBatchDto {
    batchNo?: string;
    product?: string;
    barcode?: string;
    quantity?: number;
    creationDate?: Date;
    expiryDate?: Date;
}

export interface IBatchResponse {
    items: IBatch[];
    count: number;
}

export const getBatchesFromBack = async (queryString: string): Promise<IBatchResponse> => {
    try {
        const result: AxiosResponse = await axios.get(`${apiURL}/generic-product-pharmacy-batches${queryString}&sort=-createdAt`)
        const data: IBatchResponse = result.data;
        return data
    } catch (err) {
        console.log(err)
        throw new Error(err)
    }

}

export const editBatch = async (id: string, dto: EditBatchDto): Promise<IBatchResponse> => {
    try {
        const result: AxiosResponse = await axios.put(`${apiURL}/generic-product-pharmacy-batches/${id}`, dto)
        const data: IBatchResponse = result.data;
        return data
    } catch (err) {
        console.log(err)
        throw new Error(err)
    }

}

export const createBatch = async (dto: CreateBatchDto): Promise<IBatchResponse> => {
    try {
        const result: AxiosResponse = await axios.post(`${apiURL}/generic-product-pharmacy-batches`, dto)
        const data: IBatchResponse = result.data;
        return data
    } catch (err) {
        console.log(err)
        throw new Error(err)
    }

}