import axios, { AxiosResponse } from "axios";
import { ICategory } from "lib/interfaces/category";
const apiURL = process.env.REACT_APP_API_URL;
const accessToken = localStorage.getItem(
  process.env.REACT_APP_ACCESS_TOKEN || "medify_access_token"
);

export interface ICategoryrResponse {
  items: ICategory[];
  count: number;
}

export const getCategoriesFromBack = async (
  queryString: string
): Promise<ICategoryrResponse> => {
  try {
    const result: AxiosResponse = await axios.get(
      `${apiURL}/categories${queryString}`,
      { headers: { Authorization: `Bearer ${accessToken}` } }
    );
    const data: ICategoryrResponse = result.data;
    return data;
  } catch (err) {
    console.log(err);
    throw new Error(err);
  }
};
