import { IGenericProduct } from './generic-product'
export interface ICashRegisterExpense {
    id: string;
    amount: number;
    canInvoiceBeCreated: boolean;
    expenseType: ExpenseType;
    supplier?: string;
    notes?: string;
    imss?: number;
    expensePaymentType: ExpensePaymentType;
    account: string;
    products: IGenericProduct[];
    paymentDate?: Date;
    pharmacy: string;
    createdAt: Date;
    updatedAt: Date;
}

export enum ExpenseType {
    GastoFijo = 'Gasto Fijo',
    PagoAProveedores = 'Pago a Proveedores',
    GastoRepentino = 'Gasto Repentino',
    Sueldo = 'Sueldo'
}

export enum ExpensePaymentType {
  Contado = "de Contado",
  Consesion = "a Consesión",
  Credito = "a Crédito",
  SinPago = "Sin Pago",
}