import axios, { AxiosResponse } from 'axios';
import { IOrder, OrderStatusType } from 'lib/interfaces/order';
const apiURL = process.env.REACT_APP_API_URL;
axios.interceptors.request.use(function (config) {
    config.headers.Authorization = `Bearer ${localStorage.getItem(process.env.REACT_APP_ACCESS_TOKEN || 'medify_access_token')}`;
    return config;
}, function (error) {
    // Do something with request error
    return Promise.reject(error);
});

export interface IOrderResponse {
    items: IOrder[];
    count: number;
}

export const getPosOrders = async (queryString: string): Promise<IOrderResponse> => {
    try {
        const result: AxiosResponse = await axios.get(`${apiURL}/order${queryString}&sort=-createdAt`)
        const data: IOrderResponse = result.data;
        return data
    } catch (err) {
        throw new Error(err)
    }

}

export interface ChangeOrderStatusDto {
    status: OrderStatusType
}

export const changeOrderStatus = async (id: string, dto: ChangeOrderStatusDto): Promise<IOrder> => {
    try {
        const result: AxiosResponse = await axios.put(`${apiURL}/order/${id}`, dto)
        const data: IOrder = result.data;
        console.log(data)
        return data
    } catch (err) {
        throw new Error(err)
    }
}


