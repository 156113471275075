import axios, { AxiosResponse } from 'axios';
import { ICashRegisterPurchaseProduct } from "lib/interfaces/cash-register-purchase-product";
const apiURL = process.env.REACT_APP_API_URL;
axios.interceptors.request.use(function (config) {
    config.headers.Authorization = `Bearer ${localStorage.getItem(process.env.REACT_APP_ACCESS_TOKEN || 'medify_access_token')}`;
    return config;
}, function (error) {
    // Do something with request error
    return Promise.reject(error);
});

export interface ICashierRegisterPurchaseProductsResponse {
    items: ICashRegisterPurchaseProduct[];
    count: number;
}

export const getPurchaseProductsFromBack = async (queryString: string): Promise<ICashierRegisterPurchaseProductsResponse> => {
    try {
        const result: AxiosResponse = await axios.get(`${apiURL}/cash-register-purchase-product${queryString}&populate=reference&sort=-createdAt`)
        const data: ICashierRegisterPurchaseProductsResponse = result.data;
        return data
    } catch (err) {
        console.log(err)
        throw new Error(err)
    }

}
