import React from "react";
import { MIcon } from "../../../theme";
import { PATH_APP } from "../../../routes/paths";

// ----------------------------------------------------------------------

const path = (name) => `/static/icons/navbar/${name}.svg`;

const ICONS = {
  authenticator: <MIcon src={path("ic_authenticator")} />,
  blog: <MIcon src={path("ic_blog")} />,
  calendar: <MIcon src={path("ic_calendar")} />,
  cart: <MIcon src={path("ic_cart")} />,
  charts: <MIcon src={path("ic_charts")} />,
  chat: <MIcon src={path("ic_chat")} />,
  components: <MIcon src={path("ic_components")} />,
  dashboard: <MIcon src={path("ic_dashboard")} />,
  editor: <MIcon src={path("ic_editor")} />,
  elements: <MIcon src={path("ic_elements")} />,
  error: <MIcon src={path("ic_error")} />,
  mail: <MIcon src={path("ic_mail")} />,
  map: <MIcon src={path("ic_map")} />,
  page: <MIcon src={path("ic_page")} />,
  user: <MIcon src={path("ic_user")} />,
  upload: <MIcon src={path("ic_upload")} />,
  copy: <MIcon src={path("ic_copy")} />,
  carousel: <MIcon src={path("ic_carousel")} />,
  language: <MIcon src={path("ic_language")} />,
};

export const navConfigAdmin = [
  // MANAGEMENT
  // ----------------------------------------------------------------------
  {
    subheader: "Admin",
    items: [
      // MANAGEMENT : E-COMMERCE
      // ----------------------------------------------------------------------
      {
        title: "Salud de la Farmacia",
        icon: ICONS.error,
        href: PATH_APP.management.eCommerce.root,
        items: [
          {
            title: "Movimientos Peligrosos",
            href: PATH_APP.admin.dangerouseMovements,
          },
        ],
      },
    ],
  },
];

const navConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: "general",
    items: [
      {
        title: "Inicio",
        icon: ICONS.dashboard,
        href: PATH_APP.general.ecommerce,
      },
      // {
      //   title: 'Cuenta',
      //   icon: ICONS.user,
      //   href: PATH_APP.management.user.account,
      // },
    ],
  },
  // MANAGEMENT
  // ----------------------------------------------------------------------
  {
    subheader: "Operaciones",
    items: [
      // MANAGEMENT : E-COMMERCE
      // ----------------------------------------------------------------------
      {
        title: "Ventas",
        icon: ICONS.cart,
        href: PATH_APP.management.eCommerce.root,
        items: [
          {
            title: "Cobrar",
            href: PATH_APP.management.eCommerce.checkout,
          },
          {
            title: "Ventas",
            href: PATH_APP.management.eCommerce.purchases,
          },
          {
            title: "Cortes de caja",
            href: PATH_APP.management.eCommerce.cashRegisterCutListView,
          },
          {
            title: "Cierres",
            href: PATH_APP.management.eCommerce.cashRegisterCutClosedListView,
          },
        ],
      },
      {
        title: "Gastos",
        icon: ICONS.page,
        href: PATH_APP.management.eCommerce.root,
        items: [
          {
            title: "Registrar gasto",
            href: PATH_APP.management.expenses.createExpenese,
          },
          {
            title: "Ver gastos",
            href: PATH_APP.management.expenses.expensesListView,
          },
        ],
      },
      {
        title: "Inventario",
        icon: ICONS.components,
        href: PATH_APP.management.eCommerce.root,
        items: [
          {
            title: "Productos",
            href: PATH_APP.management.eCommerce.products,
          },
          // {
          //   title: 'Compras',
          //   href: PATH_APP.management.eCommerce.root,
          // },
          {
            title: "Proveedores",
            href: PATH_APP.management.inventory.supplierListView,
          },
          {
            title: "Clientes",
            href: PATH_APP.management.inventory.clientListView,
          },

          {
            title: "Inventario faltante",
            href: PATH_APP.management.inventory.purchasedProducts,
          },
          {
            title: "Compra de nuevo inventario",
            href: PATH_APP.management.inventory.restockAndReajustment,
          },
          {
            title: "Por Expirar",
            href: PATH_APP.management.inventory.batchListView,
          },
          {
            title: "Cambio de Precios",
            href: PATH_APP.management.inventory.productPriceUpdated,
          },
          {
            title: "Ajuste de Inventario",
            href: PATH_APP.management.inventory.inventoryAdjustment,
          },
        ],
      },
    ],
  },
];

export default navConfig;
