import React from 'react';
// import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';

// ----------------------------------------------------------------------

// Logo.propTypes = {
//   className: PropTypes.string,
// };

export interface ILogoProps {
  className?: string;
  [key: string]: any;
}

function Logo({ className, ...other }: ILogoProps) {
  return (
    <Box
      component="img"
      height={82}
      className={className}
      {...{
        alt: 'logo',
        src: '/static/brand/logo_full_omni.svg',
      }}
      {...other}
    />
  );
}

export default Logo;
