import React from "react";
// import PropTypes from 'prop-types';
import useAuth from "../hooks/useAuth";
import { MAvatar } from "../theme";
import createAvatar from "../utils/createAvatar";
import { Typography } from '@material-ui/core';

// ----------------------------------------------------------------------

// MyAvatar.propTypes = {
//   className: PropTypes.string
// };

export interface IMAvatarProps {
  className?: string;
  [key: string]: any;
  name?: string;
  image?: string;
  isPharmacy?: boolean;
}

function MyAvatar({
  className,
  name,
  image,
  isPharmacy,
  ...other
}: IMAvatarProps) {
  const { cashier } = useAuth();

  return (
    <div className="avatar-pharmacy">
      <MAvatar
        src={image}
        alt={cashier?.firstName ? "cajero" : cashier?.firstName}
        color={cashier?.imgUrl ? "default" : (createAvatar(name).color as any)}
        className={className}
        {...other}
      >
        {createAvatar(name).name}
      </MAvatar>

      {isPharmacy ? (
        <Typography
          variant="subtitle2"
          style={{ marginLeft: "30px", marginTop: "-5px" }}
        >
          {name}
        </Typography>
      ) : null}
    </div>
  );
}

export default MyAvatar;
