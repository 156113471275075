import axios, { AxiosResponse } from "axios";
import { IAdmin } from "lib/interfaces/admin";
import { AdminPlan } from "../enums/admin-plan.enum";

const apiURL = process.env.REACT_APP_API_URL;

export interface AddressInterface {
  address: string;
  marker: {
    lat: number;
    lng: number;
    type: string;
  };
}

export interface CreateAdminPharmacyAndCashierDto {
  email: string;
  adminPhonenumber: string;
  adminFirstname: string;
  adminLastname: string;
  password: string;
  pharmacyName: string;
  pharamacyRFC?: string;
  plan: AdminPlan;
  pharmacyAddress: AddressInterface;
  hasReadAndAgreedEndUserAgreement: boolean;
}

axios.interceptors.request.use(
  function (config) {
    config.headers.Authorization = `Bearer ${localStorage.getItem(
      process.env.REACT_APP_ACCESS_TOKEN || "medify_access_token"
    )}`;
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

export const createAdminPhramacyAndCashier = async (
  dto: CreateAdminPharmacyAndCashierDto
): Promise<any> => {
  const result: AxiosResponse = await axios.post(
    `${apiURL}/admin/pharmacy`,
    dto
  );
  const data: any = result.data;
  return data;
};

export const verifyAdmin = async (
  email: string,
  token: string
): Promise<any> => {
  const result: AxiosResponse = await axios.get(
    `${apiURL}/auth/admin/verify?email=${encodeURI(email)}&token=${token}`
  );
  const data: any = result.data;
  return data;
};

export interface IAdminLoginResponse {
  accessToken: string;
  account: IAdmin;
}

export const loginAdmin = async (
  email: string,
  password: string
): Promise<IAdminLoginResponse> => {
  const body = {
    email,
    password,
  };
  const result: AxiosResponse = await axios.post(
    `${apiURL}/auth/admin/login`,
    body
  );
  const data: IAdminLoginResponse = result.data;
  if (!data.accessToken) {
    throw new Error("Credenciales equivocadas");
  }

  return data;
};

export const getSelfAdmin = async (): Promise<IAdmin> => {
  const result: AxiosResponse = await axios.get(`${apiURL}/admin/self`);
  const data: IAdmin = result.data;

  return data;
};

export interface UpdateAdminDto {
  email?: string;
  firstName?: string;
  lastName?: string;
  pharmacy?: string;
}

export const updateAdmin = async (dto: UpdateAdminDto): Promise<IAdmin> => {
  const result: AxiosResponse = await axios.put(`${apiURL}/admin/self`, dto);
  const data: IAdmin = result.data;

  return data;
};

export const adminForgotPassword = async (email: string) => {
  return axios.post(`${apiURL}/auth/admin/forgot-password`, { email });
};

export const adminResetPassword = async (password: string) => {
  const result: AxiosResponse = await axios.put(
    `${apiURL}/admin/self/password`,
    { new: password }
  );
  return result;
};
