import jwtDecode from 'jwt-decode';
import axios from '../../utils/axios';
import { createSlice } from '@reduxjs/toolkit';
import { ICashier } from 'lib/interfaces/cashier';
import { IPharmacy } from 'lib/interfaces/pharmacy';
import { getPharmacy } from 'lib/api/pharmacy';
import { ICashierLoginResponse } from 'lib/api/cashier';
import { getSelfAdmin, IAdminLoginResponse } from 'lib/api/admin';

// ----------------------------------------------------------------------

export type AuthStatesEnum =
  "Unauthenticated" | "AuthenticatedWithoutCachierRegister" | "Authenticated"


//const apiURL = process.env.REACT_APP_API_URL;
const isBrowser = typeof window !== 'undefined';

axios.interceptors.request.use(function (config) {
  config.headers.Authorization = `Bearer ${localStorage.getItem(process.env.REACT_APP_ACCESS_TOKEN || 'medify_access_token')}`;
  return config;
}, function (error) {
  // Do something with request error
  return Promise.reject(error);
});

const initialState = {
  isLoading: false,
  isAdmin: JSON.parse(localStorage.getItem(process.env.REACT_APP_MEDIFY_IS_ADMIN || 'medify_pharmacy_is_admin')),
  admin: JSON.parse(localStorage.getItem(process.env.REACT_APP_PHARMACY_ADMIN || 'medify_pharmacy_admin')) || null,
  isAuthenticated: isBrowser && !!localStorage.getItem(process.env.REACT_APP_ACCESS_TOKEN || 'medify_access_token'),
  AuthenticatedState: "Unauthenticated",
  cashier: JSON.parse(localStorage.getItem(process.env.REACT_APP_MEDIFY_CASHIER || 'medify_cashier')) as unknown as ICashier,
  pharmacy: JSON.parse(localStorage.getItem(process.env.REACT_APP_MEDIFY_PHARMACY || 'medify_pharmacy')) as unknown as IPharmacy,
  // cashRegister: JSON.parse(localStorage.getItem(process.env.REACT_APP_CASH_REGISTER || 'medify_cash_register')) as unknown as ICashierRegister,
  accessToken: localStorage.getItem(process.env.REACT_APP_ACCESS_TOKEN || 'medify_access_token'),
  cashierRegisterId: localStorage.getItem(process.env.REACT_APP_CASH_REGISTER || 'medify_cashier_register_id'),
  pharmacyId: localStorage.getItem(process.env.REACT_APP_PHARMACY_ID || 'medify_pharmacy_id'),
  user: {},
};



const slice = createSlice({
  name: 'authJwt',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // INITIALISE
    getInitialize(state, action) {
      state.isLoading = false;
      state.isAuthenticated = action.payload.isAuthenticated;
      state.user = action.payload.user;
    },

    // LOGIN
    loginSuccess(state, action) {
      state.isLoading = false;
      state.isAuthenticated = true
      state.pharmacy = action.payload.cashier.pharmacy
      state.cashier = action.payload.cashier;
      state.AuthenticatedState = "Authenticated"
      state.accessToken = action.payload.accessToken
      state.pharmacyId = action.payload.cashier.pharmacy.id
      //  state.cashRegister = action.payload.cashRegister;
      state.cashier = action.payload.cashier;
      localStorage.setItem(process.env.REACT_APP_ACCESS_TOKEN || 'medify_access_token', action.payload.accessToken);
      localStorage.setItem(process.env.REACT_APP_PHARMACY_IS_ADMIN || 'medify_pharmacy_is_admin', null);
      localStorage.setItem(process.env.REACT_APP_MEDIFY_PHARMACY || 'medify_pharmacy', JSON.stringify(action.payload.cashier.pharmacy))
      localStorage.setItem(process.env.REACT_APP_PHARMACY_ID || 'medify_pharmacy_id', action.payload.cashier.pharmacy.id);
      //     localStorage.setItem(process.env.REACT_APP_CASH_REGISTER || 'medify_cash_register_id', action.payload.cashier.account.id)
      localStorage.setItem(process.env.REACT_APP_MEDIFY_CASHIER || 'medify_cashier', JSON.stringify(action.payload.cashier))
      localStorage.setItem(process.env.REACT_APP_MEDIFY_PHARMACY || 'medify_pharmacy', JSON.stringify(action.payload.cashier.pharmacy))
      //     localStorage.setItem(process.env.REACT_APP_CASH_REGISTER || 'medify_cash_register', JSON.stringify(action.payload.cashRegister))
    },
    loginSuccessAdmin(state, action) {
      state.isLoading = false;
      state.isAuthenticated = true
      state.pharmacy = action.payload.admin.pharmacy
      state.AuthenticatedState = "Authenticated"
      state.accessToken = action.payload.accessToken
      state.pharmacyId = action.payload.admin.pharmacy.id
      state.isAdmin = true;
      state.admin = action.payload.admin

      //  state.cashRegister = action.payload.cashRegister;
      localStorage.setItem(process.env.REACT_APP_ACCESS_TOKEN || 'medify_access_token', action.payload.accessToken);
      localStorage.setItem(process.env.REACT_APP_PHARMACY_ADMIN || 'medify_pharmacy_admin', JSON.stringify(action.payload.admin));
      state.cashier = action.payload.cashier;
      localStorage.setItem(process.env.REACT_APP_PHARMACY_IS_ADMIN || 'medify_pharmacy_is_admin', 'true');
      localStorage.setItem(process.env.REACT_APP_PHARMACY_ID || 'medify_pharmacy_id', action.payload.admin.pharmacy.id);
      //     localStorage.setItem(process.env.REACT_APP_CASH_REGISTER || 'medify_cash_register_id', action.payload.cashier.account.id)
      // localStorage.setItem(process.env.REACT_APP_MEDIFY_CASHIER || 'medify_cashier', JSON.stringify(action.payload.admin))
      localStorage.setItem(process.env.REACT_APP_MEDIFY_PHARMACY || 'medify_pharmacy', JSON.stringify(action.payload.admin.pharmacy))
      //     localStorage.setItem(process.env.REACT_APP_CASH_REGISTER || 'medify_cash_register', JSON.stringify(action.payload.cashRegister))
    },
    changedPharmacyAdmin(state, action) {
      state.pharmacy = action.payload.pharmacy
      state.pharmacyId = action.payload.pharmacy.id
      localStorage.setItem(process.env.REACT_APP_PHARMACY_ID || 'medify_pharmacy_id', action.payload.pharmacy.id);
      localStorage.setItem(process.env.REACT_APP_MEDIFY_PHARMACY || 'medify_pharmacy', JSON.stringify(action.payload.pharmacy))
    },
    selectedCashier(state, action) {
      localStorage.setItem(process.env.REACT_APP_CASH_REGISTER || 'medify_cashier_register_id', action.payload.cashierRegisterId)
      state.isAuthenticated = true;
      state.cashierRegisterId = action.payload.cashierRegisterId;
      state.AuthenticatedState = "Authenticated";

    }
    ,
    // REGISTER
    registerSuccess(state, action) {
      state.isAuthenticated = true;
      state.AuthenticatedState = "AuthenticatedWithoutCachierRegister"
      state.user = action.payload.user;
    },
    setCashierAccount(state, action) {
      const pharmacy = action.payload.items[0];
      state.pharmacy = pharmacy;
    },
    setAdmin(state, action) {
      state.pharmacy = action.payload.pharmacy;
      state.pharmacyId = action.payload.pharmacy.id;
      state.admin = action.payload
      localStorage.setItem(process.env.REACT_APP_PHARMACY_ADMIN || 'medify_pharmacy_admin', JSON.stringify(action.payload));
      localStorage.setItem(process.env.REACT_APP_MEDIFY_PHARMACY || 'medify_pharmacy', JSON.stringify(action.payload.pharmacy))
      localStorage.setItem(process.env.REACT_APP_PHARMACY_ID || 'medify_pharmacy_id', action.payload.pharmacy.id);
    },
    // LOGOUT
    logoutSuccess(state) {
      state.admin = null;
      state.isAdmin = false;
      state.isAuthenticated = false;
      state.AuthenticatedState = "Unauthenticated"
      localStorage.setItem(process.env.REACT_APP_PHARMACY_IS_ADMIN || 'medify_pharmacy_is_admin', null);
      localStorage.setItem(process.env.REACT_APP_PHARMACY_ADMIN || 'medify_pharmacy_admin', null);
      localStorage.setItem(process.env.REACT_APP_PHARMACY_ID || 'medify_pharmacy_id', null);
      localStorage.setItem(process.env.REACT_APP_CASH_REGISTER || 'medify_cash_register_id', null)
      localStorage.setItem(process.env.REACT_APP_MEDIFY_CASHIER || 'medify_cashier', null)
      localStorage.setItem(process.env.REACT_APP_MEDIFY_PHARMACY || 'medify_pharmacy', null)
      localStorage.setItem(process.env.REACT_APP_CASH_REGISTER || 'medify_cash_register', null)
      localStorage.setItem(process.env.REACT_APP_ACCESS_TOKEN || 'medify_access_token', null)
      state.isLoading = false;

      state.cashier = null;
      state.pharmacy = null
      state.accessToken = null
      state.pharmacyId = null
      //     state.cashRegister = null
      state.cashier = null
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

const isValidToken = (accessToken) => {
  if (!accessToken) {
    return false;
  }
  const decoded = jwtDecode(accessToken);
  const currentTime = Date.now() / 1000;

  return (decoded as any).exp > currentTime;
};

const setSession = (accessToken) => {
  if (accessToken) {
    localStorage.setItem(process.env.REACT_APP_ACCESS_TOKEN || 'medify_access_token', accessToken);

  } else {
    localStorage.removeItem(process.env.REACT_APP_ACCESS_TOKEN || 'medify_access_token');
    delete axios.defaults.headers.common.Authorization;
  }
};

// ----------------------------------------------------------------------

export function selectedCashier(cashierId) {
  return (dispatch) => {
    dispatch(slice.actions.selectedCashier(cashierId));
  };
}

// ----------------------------------------------------------------------

export function login(response: ICashierLoginResponse) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    (setSession(response.accessToken));
    dispatch(slice.actions.loginSuccess({ accessToken: response.accessToken, cashier: response.account }));
  };
}

// ----------------------------------------------------------------------

export function loginAsAdmin(response: IAdminLoginResponse) {

  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    (setSession(response.accessToken));
    dispatch(slice.actions.loginSuccessAdmin({ accessToken: response.accessToken, admin: response.account }));
  };
}

// ----------------------------------------------------------------------

export function getAdmin() {
  return async (dispatch) => {
    const result = await getSelfAdmin()
    dispatch(slice.actions.setAdmin(result));
  };
}

// ----------------------------------------------------------------------


export function register({ email, password, firstName, lastName }) {
  return async (dispatch) => {
    const response = await axios.post('/api/account/register', {
      email,
      password,
      firstName,
      lastName,
    });
    const { accessToken, user } = response.data;

    window.localStorage.setItem('accessToken', accessToken);
    dispatch(slice.actions.registerSuccess({ user }));
  };
}

// ----------------------------------------------------------------------

export function logout() {
  return async (dispatch) => {
    setSession(null);
    dispatch(slice.actions.logoutSuccess());
  };
}

// ----------------------------------------------------------------------


export function updateCashierProfile(id) {
  return async (dispatch) => {
    const result = await getPharmacy(id)
    dispatch(slice.actions.setCashierAccount(result));
  };
}

// ----------------------------------------------------------------------

export function getInitialize() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const accessToken = window.localStorage.getItem(process.env.REACT_APP_ACCESS_TOKEN || 'medify_access_token');
      if (accessToken && isValidToken(accessToken)) {

        (setSession(accessToken));

        const response = await axios.get('/api/account/my-account');
        dispatch(
          slice.actions.getInitialize({
            isAuthenticated: "AuthenticatedWithoutCachierRegister",
            user: response.data.user,
          })
        );
      } else {
        dispatch(
          slice.actions.getInitialize({
            isAuthenticated: "Unauthenticated",
            user: null,
          })
        );
      }
    } catch (error) {
      console.error(error);
      dispatch(
        slice.actions.getInitialize({
          isAuthenticated: "Unauthenticated",
          user: null,
        })
      );
    }
  };
}
