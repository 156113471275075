

import { createSlice } from '@reduxjs/toolkit';
import { DateRange } from './generic-product';
import { IFilter } from 'redux/shared/interfaces';
import { getExpensesFromBack } from '../../lib/api/cash-register-expense'
import { ICashRegisterExpense } from 'lib/interfaces/cash-register-expense';
// ----------------------------------------------------------------------


export enum StatisticsType {
    CashRegisterPurchase = 'CashRegisterPurchase',
    CashRegisterExpense = 'CashRegisterExpense'
}

export interface IExpensesReducer {
    expenses: ICashRegisterExpense[]
    isLoading: boolean
    expensesCount: number
    error: boolean

}

const initialState: IExpensesReducer = {
    expenses: [] as ICashRegisterExpense[],
    expensesCount: 0,
    isLoading: false,
    error: false,
};


const slice = createSlice({
    name: 'expenses',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },
        // GET EXPENSES
        getExpensesSuccess(state, action) {
            state.isLoading = false;
            state.expenses = action.payload.items;
            state.expensesCount = action.payload.count;
        },
    },
});

// Reducer
export default slice.reducer;

// Actions
// export const {
//     ,
// } = slice.actions;


function getDateFilter(dateRange: DateRange): Map<string, any> {
    if (!dateRange) {
        return
    }
    let filter = {} as any;
    if (!!dateRange.startDate) {
        filter = {
            ...filter,
            createdAt: !!filter?.createdAt
                ? { ...filter?.createdAt, $gte: dateRange.startDate }
                : { $gte: dateRange.startDate },
        };
    }
    if (!!dateRange.endDate) {
        filter = {
            ...filter,
            createdAt: !!filter?.createdAt ? { ...filter?.createdAt, $lt: dateRange.endDate } : { $lt: dateRange.endDate },
        };
    }
    return filter;
}

export const selectQueryString = (filters: IFilter) => {
    let queryString = '?';
    const filter = {
        // ...getSearchFilter(filters.search),
        ...getDateFilter(filters.dateRange),
        pharmacy: filters.pharmacy
    };
    if (filters.supplier) {
        filter['supplier'] = filters.supplier
    }
    queryString += Object.keys(filter).length > 0 ? `filter=${JSON.stringify(filter)}&` : '';
    queryString += `limit=${filters.pagination.limit}&skip=${filters.pagination.skip || 0}`;
    /*
    if (!!state.sorting.active) {
      queryString += `&sort=${state.sorting.direction === 'asc' ? '' : '-'}${state.sorting.active}`;
    } */
    return queryString;
};




// ----------------------------------------------------------------------

export function getExpenses(query: IFilter) {
    return async (dispatch) => {
        dispatch(slice.actions.startLoading());
        try {
            const queryString = selectQueryString(query)
            const response = await getExpensesFromBack(queryString)
            dispatch(slice.actions.getExpensesSuccess(response));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------



