import clsx from "clsx";
import React, { useState } from "react";
import Account from "./Account";
import { Icon } from "@iconify/react";
import { useSnackbar } from "notistack";
import menu2Fill from "@iconify-icons/eva/menu-2-fill";
import { makeStyles, alpha } from "@material-ui/core/styles";
import { getAdmin } from "redux/slices/authJwt";
import {
  Box,
  AppBar,
  Hidden,
  Toolbar,
  IconButton,
  Select,
  MenuItem,
  OutlinedInput,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import Modal from "react-modal";
import { updateAdmin } from "lib/api/admin";
import { IRootState } from "redux/rootReducer";
// import Notifications from "./Notifications";

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;
const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 92;

const customModalStyles = {
  content: {
    width: "70%",
    top: "55%",
    left: "60%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: "none",
    backdropFilter: "blur(8px)",
    backgroundColor: alpha(theme.palette.background.default, 0.72),
    [theme.breakpoints.up("lg")]: {
      paddingLeft: DRAWER_WIDTH,
    },
  },
  toolbar: {
    minHeight: APPBAR_MOBILE,
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(0, 5),
    },
    [theme.breakpoints.up("lg")]: {
      minHeight: APPBAR_DESKTOP,
    },
  },
}));

// ----------------------------------------------------------------------

// TopBar.propTypes = {
//   onOpenNav: PropTypes.func,
//   className: PropTypes.string,
// };

export interface ITopBarProps {
  onOpenNav?: any;
  className?: string;
  [key: string]: any;
}

function TopBar({ onOpenNav, className }: ITopBarProps) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [openTutorialModal, setOpenTutorialModal] = useState(false);
  const dispatch = useDispatch();
  let { pharmacy, isAdmin, admin } = useSelector(
    (state: IRootState) => state.authJwt
  );

  const onChangeAdminPharmacy = async (id) => {
    try {
      await updateAdmin({ pharmacy: id });
      enqueueSnackbar("Farmacia Cambiada", {
        variant: "success",
        autoHideDuration: 3000,
      });
      dispatch(getAdmin());
    } catch (err) {
      enqueueSnackbar("Error al cambiar Farmacia", {
        variant: "error",
        autoHideDuration: 3000,
      });
    }
  };

  return (
    <AppBar className={clsx(classes.root, className)}>
      <Toolbar className={classes.toolbar}>
        <Hidden lgUp>
          <IconButton
            onClick={onOpenNav}
            {...{
              sx: {
                mr: 1,
                color: "text.primary",
              },
            }}
          >
            <Icon icon={menu2Fill} />
          </IconButton>
        </Hidden>

        <Modal
          isOpen={openTutorialModal}
          onAfterOpen={null}
          onRequestClose={() => setOpenTutorialModal(false)}
          style={customModalStyles}
          contentLabel="Example Modal"
        >
          <div
            id="tolstoy-container"
            style={{
              lineHeight: 0,
              overflow: "hidden",
              height: "100%",
              width: "100%",
              textAlign: "center",
            }}
          >
            <iframe
              title="totlstoy"
              id="tolstoy"
              src="https://player.gotolstoy.com/u8cv4zk8wg82k?host"
              style={{ width: "100%", height: "540px", maxWidth: "960px" }}
              scrolling="no"
              allow="autoplay *; clipboard-write *;camera *; microphone *; encrypted-media *; fullscreen *; display-capture *;"
            ></iframe>
            <script src="https://widget.gotolstoy.com/script.js" defer></script>
          </div>
        </Modal>
        <Box {...({ sx: { flexGrow: 1 } } as any)} />
        {isAdmin && admin?.allPharmacies.length > 0 && (
          <Select
            style={{ width: "200px", marginRight: "1em" }}
            labelId="select-pharmacy"
            id="demo-simple-select-disabled"
            value={pharmacy.id}
            input={<OutlinedInput label="Permisos" />}
            label="Farmacia"
            onChange={(e) => onChangeAdminPharmacy(e.target.value)}
          >
            {admin.allPharmacies.map((pharmacy) => (
              <MenuItem value={pharmacy.id}>
                <em>{pharmacy.name}</em>
              </MenuItem>
            ))}
          </Select>
        )}

        <Box
          sx={
            {
              display: "flex",
              alignItems: "center",
              "& > *:not(:first-of-type)": {
                ml: {
                  xs: 0.5,
                  sm: 2,
                  lg: 3,
                },
              },
            } as any
          }
        >
          {/* <Languages /> */}
          {/* <Notifications /> */}
          {/* <Settings /> */}
          <Account />
        </Box>
      </Toolbar>
    </AppBar>
  );
}

export default TopBar;
