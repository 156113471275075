

import { createSlice } from '@reduxjs/toolkit';
import { IFilter } from 'redux/shared/interfaces';
import { ICashRegisterPurchase } from 'lib/interfaces/cash-register-purchase'
import { ICashierRegisterCut } from 'lib/interfaces/cash-register-cut';
import { getAvailableCashRegisterPurchase, getCashRegisterCutsFromBack } from 'lib/api/cash-register-cut';
import { getAvailableCashRegisterExpenses } from 'lib/api/cash-register-expense';
import { ICashRegisterExpense } from 'lib/interfaces/cash-register-expense';
// ----------------------------------------------------------------------


const initialState = {
    isLoading: false,
    error: false,
    cashRegisterCuts: [] as ICashierRegisterCut[],
    cashRegisterCut: null as ICashierRegisterCut,
    availableCashRegisterPurchases: [] as ICashRegisterPurchase[],
    availableCashRegisterExpenses: [] as ICashRegisterExpense[],
    minDate: '',
    maxDate: '',
    cashRegisterCutCount: 0,
    sortBy: null,
    filters: {
        gender: [],
        category: 'All',
        colors: [],
        priceRange: '',
        rating: '',
    },
};

const calculateMinDate = (cashRegisterPurchases: ICashRegisterPurchase[]): string => {
    return cashRegisterPurchases[0].createdAt as unknown as string;
}

const calculateMaxDate = (cashRegisterPurchases: ICashRegisterPurchase[]) => {
    return cashRegisterPurchases[cashRegisterPurchases.length - 1].createdAt as unknown as string;
}

const slice = createSlice({
    name: 'cashRegisterCut',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },
        // GET PURCHASES
        getAvailablePurchasesAndExpensesSuccess(state, action) {
            state.isLoading = false;
            state.availableCashRegisterPurchases = action.payload.responsePurchase.items;
            state.availableCashRegisterExpenses = action.payload.responseExpense.items;
            const minDate = calculateMinDate(action.payload.responsePurchase.items)
            state.minDate = minDate
            state.maxDate = calculateMaxDate(action.payload.responsePurchase.items)
        },

        // GET PURCHASES
        getCashRegisterCutsSuccess(state, action) {
            state.isLoading = false;
            state.cashRegisterCuts = action.payload.items;
            state.cashRegisterCutCount = action.payload.count;
        },
        // GET PURCHASES
        getCutSuccess(state, action) {
            state.isLoading = false;
            state.cashRegisterCut = action.payload;

        },

        filterCashRegisterCut(state, action) {
            state.filters.gender = action.payload.gender;
            state.filters.category = action.payload.category;
            state.filters.colors = action.payload.colors;
            state.filters.priceRange = action.payload.priceRange;
            state.filters.rating = action.payload.rating;
        },
    },
});

// Reducer
export default slice.reducer;

// Actions
export const {
    filterCashRegisterCut,
} = slice.actions;


// ----------------------------------------------------------------------

export function getAvailablePurchasesAndExpenses(accessToken: string) {
    return async (dispatch) => {
        dispatch(slice.actions.startLoading());
        try {
            const responsePurchase = await getAvailableCashRegisterPurchase(accessToken)
            const responseExpense = await getAvailableCashRegisterExpenses(accessToken)
            dispatch(slice.actions.getAvailablePurchasesAndExpensesSuccess({ responsePurchase, responseExpense }));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}


// ----------------------------------------------------------------------

export function getCashRegisterCuts(query: IFilter) {
    return async (dispatch) => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await getCashRegisterCutsFromBack(query)
            dispatch(slice.actions.getCashRegisterCutsSuccess(response));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------



