import axios, { AxiosResponse } from 'axios';
import { IClient } from 'lib/interfaces/client';
const apiURL = process.env.REACT_APP_API_URL;
axios.interceptors.request.use(function (config) {
    config.headers.Authorization = `Bearer ${localStorage.getItem(process.env.REACT_APP_ACCESS_TOKEN || 'medify_access_token')}`;
    return config;
}, function (error) {
    // Do something with request error
    return Promise.reject(error);
});

export enum TypeOfClient {
    personaFisica = 'personaFisica',
    personaMoral = 'personaMoral'
}
export interface CreateClientDto {
    firstName?: string;
    lastName?: string;
    secondLastName?: string;
    companyLegalName?: string;
    phoneNumber: string;
    type: TypeOfClient;
    email: string;
    RFC: string;
    pharmacy: string;
}

export interface EditClientDto {
    firstName?: string;
    lastName?: string;
    secondLastName?: string;
    companyLegalName?: string;
    phoneNumber?: string;
    type?: TypeOfClient;
    email?: string;
    RFC?: string;
 
}

export interface IClientResponse {
    items: IClient[];
    count: number;
}

export const getClientsFromBack = async (queryString: string): Promise<IClientResponse> => {
    try {
        const result: AxiosResponse = await axios.get(`${apiURL}/client${queryString}&sort=-createdAt`)
        const data: IClientResponse = result.data;
        return data
    } catch (err) {
        throw new Error(err)
    }

}

export const editClient = async (id: string, dto: EditClientDto, accessToken: string): Promise<IClientResponse> => {
    try {
        const result: AxiosResponse = await axios.put(`${apiURL}/client/${id}`, dto)
        const data: IClientResponse = result.data;
        return data
    } catch (err) {
        console.log(err)
        throw new Error(err)
    }

}

export const createClient = async (dto: CreateClientDto, accessToken: string) => {
    try {
        const result: void | AxiosResponse = await axios.post(`${apiURL}/client`, dto, { headers: { 'Authorization': `Bearer ${accessToken}` } }).catch((err: any) => {
            if (err?.response?.data?.errorCode === 3) {
                throw new Error('No tienes privilegios')
            }
        })
        const data: IClient = (result as any).data;
        return data
    } catch (err) {
        throw new Error(err)
    }
}

export const deleteClient = async (id: string): Promise<IClient> => {
    try {
        const result: AxiosResponse = await axios.delete(`${apiURL}/Client/${id}`)
        const data: IClient = result.data;
        return data
    } catch (err) {
        console.log(err)
        throw new Error(err)
    }

}

