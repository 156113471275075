import axios, { AxiosResponse } from 'axios';
import { ICashierRegisterCutClosed } from 'lib/interfaces/cash-register-cut-closed';
import { DateRange } from 'redux/slices/generic-product';
import { IFilter } from 'redux/shared/interfaces';
import fileDownload from 'js-file-download'
const apiURL = process.env.REACT_APP_API_URL;

axios.interceptors.request.use(function (config) {
    config.headers.Authorization = `Bearer ${localStorage.getItem(process.env.REACT_APP_ACCESS_TOKEN || 'medify_access_token')}`;
    return config;
}, function (error) {
    // Do something with request error
    return Promise.reject(error);
});


export interface ICashierRegisterCutClosedResponse {
    items: ICashierRegisterCutClosed[];
    count: number;
}

export interface ICreateCashRegisterCutClosedDto {
    cashRegisterCuts: string[];
    // cashRegister: string;
    from: Date;
    to: Date;
}

export interface ICashRegisterCutClosedResponse {
    items: ICashierRegisterCutClosed[];
    count: number;
}


function getSearchFilter(search: string): Map<string, any> {
    let filter: any = {};
    if (!!search) {
        filter = {
            $or: [{ name: { $regex: search, $options: 'i' } }, { brand: { $regex: search, $options: 'i' } }, { barcode: { $regex: search, $options: 'i' } }],
        };
    }
    return filter;
}

function getDateFilter(dateRange: DateRange): Map<string, any> {
    if (!dateRange) {
        return
    }
    let filter = {} as any;
    if (!!dateRange.startDate) {
        filter = {
            ...filter,
            createdAt: !!filter?.createdAt
                ? { ...filter?.createdAt, $gte: dateRange.startDate }
                : { $gte: dateRange.startDate },
        };
    }
    if (!!dateRange.endDate) {
        filter = {
            ...filter,
            createdAt: !!filter?.createdAt ? { ...filter?.createdAt, $lt: dateRange.endDate } : { $lt: dateRange.endDate },
        };
    }
    return filter;
}

export const selectQueryString = (filters: IFilter) => {
    let queryString = '?';
    const filter = {
        ...getSearchFilter(filters.search),
        ...getDateFilter(filters.dateRange),
        pharmacy: filters.pharmacy
    };
    queryString += Object.keys(filter).length > 0 ? `filter=${JSON.stringify(filter)}&` : '';
    queryString += `limit=${filters.pagination.limit}&skip=${filters.pagination.skip}`;
    /*
    if (!!state.sorting.active) {
      queryString += `&sort=${state.sorting.direction === 'asc' ? '' : '-'}${state.sorting.active}`;
    } */
    return queryString;
};


export const getCashRegisterCutsFromBack = async (query: IFilter): Promise<ICashRegisterCutClosedResponse> => {
    try {
        const queryString = selectQueryString(query)
        const result: AxiosResponse = await axios.get(`${apiURL}/cash-register-cuts-closed${queryString}&populate=cashRegisterCuts,account&sort=-createdAt`)
        const data: ICashRegisterCutClosedResponse = result.data;
        return data
    } catch (err) {
        console.log(err)
        throw new Error(err)
    }

}

export const createCashRegisterCutClosedInBack = async (body: ICreateCashRegisterCutClosedDto, accessToken: string): Promise<ICashierRegisterCutClosedResponse> => {
    try {
        const result: AxiosResponse = await axios.post(`${apiURL}/cash-register-cuts-closed`, body, { headers: { 'Authorization': `Bearer ${accessToken}` } })
        const data: ICashierRegisterCutClosedResponse = result.data;
        return data
    } catch (err) {
        console.log(err)
        throw new Error(err)
    }

}

export const downloadInvoiceFromBack = async (id: string, accessToken: string): Promise<any> => {
    try {
        const result: AxiosResponse = await axios.get(`${apiURL}/cash-register-purchases/customer-recipe-invoice/${id}`, { headers: { 'Authorization': `Bearer ${accessToken}`, "Content-Disposition": "attachment; filename=something.zip", "Content-Type": "application/zip; charset=utf-8" }, responseType: 'blob' })
        fileDownload(result.data, `${id}.zip`)
        return id
    } catch (err) {
        console.log(err)
        throw new Error(err)
    }

}

