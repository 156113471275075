

import { createSlice } from '@reduxjs/toolkit';
import { IFilter } from 'redux/shared/interfaces';
import { ICashierRegisterCutClosed } from 'lib/interfaces/cash-register-cut-closed';
import { getCashRegisterCutsFromBack } from 'lib/api/cash-register-cut-closed';
// ----------------------------------------------------------------------


const initialState = {
    isLoading: false,
    error: false,
    cashRegisterCuts: [] as ICashierRegisterCutClosed[],
    cashRegisterCut: null as ICashierRegisterCutClosed,
    cashRegisterCutClosedCount: 0,
    sortBy: null,
    filters: {
        gender: [],
        category: 'All',
        colors: [],
        priceRange: '',
        rating: '',
    },
};


const slice = createSlice({
    name: 'cashRegisterCutClosed',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },
        getCashRegisterCutsClosedSuccess(state, action) {
            state.isLoading = false;
            state.cashRegisterCuts = action.payload.items;
            state.cashRegisterCutClosedCount = action.payload.count;
        },
        // GET PURCHASES
        getCutSuccess(state, action) {
            state.isLoading = false;
            state.cashRegisterCut = action.payload;

        },

        filterCashRegisterCut(state, action) {
            state.filters.gender = action.payload.gender;
            state.filters.category = action.payload.category;
            state.filters.colors = action.payload.colors;
            state.filters.priceRange = action.payload.priceRange;
            state.filters.rating = action.payload.rating;
        },
    },
});

// Reducer
export default slice.reducer;

// Actions
export const {
    filterCashRegisterCut,
} = slice.actions;



// ----------------------------------------------------------------------

export function getCashRegisterCutsClosed(query: IFilter) {
    return async (dispatch) => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await getCashRegisterCutsFromBack(query)
            dispatch(slice.actions.getCashRegisterCutsClosedSuccess(response));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------



