import axios, { AxiosResponse } from 'axios';
import { IPosEvent } from 'lib/interfaces/posEvent';
const apiURL = process.env.REACT_APP_API_URL;
axios.interceptors.request.use(function (config) {
    config.headers.Authorization = `Bearer ${localStorage.getItem(process.env.REACT_APP_ACCESS_TOKEN || 'medify_access_token')}`;
    return config;
}, function (error) {
    // Do something with request error
    return Promise.reject(error);
});

export interface IPosEventResponse {
    items: IPosEvent[];
    count: number;
}

export const getPosEventsFromBack = async (queryString: string): Promise<IPosEventResponse> => {
    try {
        const result: AxiosResponse = await axios.get(`${apiURL}/pos-events${queryString}&sort=-createdAt`)
        const data: IPosEventResponse = result.data;
        return data
    } catch (err) {
        throw new Error(err)
    }

}


