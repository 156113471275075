

import { createSlice } from '@reduxjs/toolkit';
import { DateRange } from './generic-product';
import { IFilter } from 'redux/shared/interfaces';
import { IPosEvent } from 'lib/interfaces/posEvent';
import { getPosEventsFromBack } from 'lib/api/posEvents';
// ----------------------------------------------------------------------



const initialState = {
    posEvents: [] as IPosEvent[],
    posEvent: {} as IPosEvent,
    error: false,
    isLoading: false,
    posEventsCount: 0,
    sortBy: null,
    filters: {
        gender: [],
        category: 'All',
        colors: [],
        priceRange: '',
        rating: '',
    },
};


const slice = createSlice({
    name: 'client',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },

        // GET POS Events
        getPosEventsSuccess(state, action) {
            state.isLoading = false;
            state.posEvents = action.payload.items;
            state.posEventsCount = action.payload.count;
        },


    },
});

// Reducer
export default slice.reducer;

// Actions
// export const {

// } = slice.actions;


function getSearchFilter(search: string): Map<string, any> {
    let filter: any = {};
    if (!!search) {
        filter = {
            $or: [{ type: { $regex: search, $options: 'i' } }],
        };
    }
    return filter;
}

function getDateFilter(dateRange: DateRange): Map<string, any> {
    if (!dateRange) {
        return
    }
    let filter = {} as any;
    if (!!dateRange.startDate) {
        filter = {
            ...filter,
            createdAt: !!filter?.createdAt
                ? { ...filter?.createdAt, $gte: dateRange.startDate }
                : { $gte: dateRange.startDate },
        };
    }
    if (!!dateRange.endDate) {
        filter = {
            ...filter,
            createdAt: !!filter?.createdAt ? { ...filter?.createdAt, $lt: dateRange.endDate } : { $lt: dateRange.endDate },
        };
    }
    return filter;
}

export const selectQueryString = (filters: IFilter) => {
    let queryString = '?';
    const filter = {
        ...getSearchFilter(filters.search),
        ...getDateFilter(filters.dateRange),
        pharmacy: filters.pharmacy
    };
    queryString += Object.keys(filter).length > 0 ? `filter=${JSON.stringify(filter)}&` : '';
    queryString += `limit=${filters.pagination.limit}&skip=${filters.pagination.skip}`;
    /*
    if (!!state.sorting.active) {
      queryString += `&sort=${state.sorting.direction === 'asc' ? '' : '-'}${state.sorting.active}`;
    } */
    return queryString;
};


// ----------------------------------------------------------------------

export function getPosEvents(query: IFilter) {
    return async (dispatch) => {
        //dispatch(slice.actions.startLoading());
        try {
            const queryString = selectQueryString(query)
            const response = await getPosEventsFromBack(queryString)
            dispatch(slice.actions.getPosEventsSuccess(response));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

