import { AddressInterface } from "lib/api/admin";
import { GEOJson } from "./pharmacy";
import { IUser } from "./user";

export enum OrderStatusType {
  New = "Nuevo",
  Reserved = "Apartado",
  Completed = "Entregado",
  Canceled = "Pedido Cancelado",
}

export class IOrderProduct {
  referenceId: string;
  brand: string;
  IVA: number;
  IEPS: number;
  discount: number;
  totalPriceWithIVAAndIEPS: number;
  name: string;
  description?: string;
  category?: string;
  subcategory?: string;
  imgUrl: string;
  barcode: string;
  price: number;
  quantity: number;
  controlled: boolean;
}

export interface IOrder {
  id: string;
  externalId?: string;
  ABTestVersion?: string;
  user: IUser;
  pharmacy: string;
  statusNum: number;
  ticketNumber: string;
  status: OrderStatusType;
  products: Array<IOrderProduct>;
  discount: number;
  address: AddressInterface;
  totalIEPS: number;
  totalIVA: number;
  totalDiscount: number;
  phoneNumber: string;
  location?: GEOJson;
  createdAt: Date;
  updatedAt: Date;
  totalPrice: number;
  totalPriceWithIVAAndIEPS: number;
}
