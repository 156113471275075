import { createSlice } from "@reduxjs/toolkit";
import { ICategory } from "lib/interfaces/category";
import { getCategoriesFromBack } from "lib/api/category";
import { CategoriesStoreType } from "lib/enums/categories-store-type.enum";
// ----------------------------------------------------------------------

const initialState = {
  categories: [] as ICategory[],
  category: {} as ICategory,
  error: false,
  isLoading: false,
  categoryCount: 0,
  sortBy: null,
  filters: {
    gender: [],
    category: "All",
    colors: [],
    priceRange: "",
    rating: "",
  },
};

const slice = createSlice({
  name: "category",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET Categories
    getCategoriesSuccess(state, action) {
      state.isLoading = false;
      state.categories = action.payload.items;
      state.categoryCount = action.payload.count;
    },
    // GET Categories
    getCategorySuccess(state, action) {
      state.isLoading = false;
      state.category = action.payload;
    },

    //  SORT & FILTER PRODUCTS
    sortByCategories(state, action) {
      state.sortBy = action.payload;
    },

    filterCategories(state, action) {
      state.filters.gender = action.payload.gender;
      state.filters.category = action.payload.category;
      state.filters.colors = action.payload.colors;
      state.filters.priceRange = action.payload.priceRange;
      state.filters.rating = action.payload.rating;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { filterCategories } = slice.actions;

export function getCategoryByStoreType(storeType: CategoriesStoreType) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await getCategoriesFromBack(`?storeType=${storeType}`);
      dispatch(slice.actions.getCategoriesSuccess(response));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
