import clsx from 'clsx';
import React from 'react';
import { motion } from 'framer-motion';
import { varSmallClick } from './';
import { makeStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';

// ----------------------------------------------------------------------

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'inline-flex',
  },
}));

// ----------------------------------------------------------------------

export interface IButtonAnimateProps {
  small?: boolean;
  children?: React.ReactNode;
  className?: string;
}

function ButtonAnimate({ children, className, ...other }: IButtonAnimateProps) {
  const classes = useStyles();

  return (
    <Box
      component={motion.div}
      
      className={clsx(classes.root, className)}
      {...{ variants: varSmallClick, whileTap: 'tap', whileHover: 'hover' }}
      {...other}
    >
      {children}
    </Box>
  );
}

export default ButtonAnimate;
