import axios from '../../utils/axios';
import { createSlice } from '@reduxjs/toolkit';
import { CreateGenericProductDto } from "lib/api/generic-product";
import faker from "faker";
import { ExpensePaymentType } from "lib/interfaces/cash-register-expense";
import { CreateBatchDto } from "lib/api/batch";

// ----------------------------------------------------------------------

const apiURl = process.env.REACT_APP_API_URL;
axios.interceptors.request.use(
  function (config) {
    config.headers.Authorization = `Bearer ${localStorage.getItem(
      process.env.REACT_APP_ACCESS_TOKEN || "medify_access_token"
    )}`;
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

export enum RestockType {
  new = "NUEVO",
  restock = "RESTOCK",
}

export interface DateRange {
  startDate: Date;
  endDate: Date;
}

export interface RestockBatch extends CreateBatchDto {
  id?: string;
}

const initialState = {
  isLoading: false,
  error: false,
  addToExpenses: false,
  supplier: "",
  externalInvoice: "",
  externalOrderId: "",
  selectedBatches: [] as RestockBatch[],
  expensePaymentType: ExpensePaymentType.Contado,
  newProducts: [] as CreateOrUpdateGenericProductDto[],
  restockProducts: [] as CreateOrUpdateGenericProductDto[],
  toCheckRestockOrNewProducts: [] as CreateOrUpdateGenericProductDto[],
  product: null as CreateOrUpdateGenericProductDto,
  productsToLookFor: [] as CreateGenericProductDto[],
  productCount: 0,
  sortBy: null,
  filters: {
    gender: [],
    category: "All",
    colors: [],
    priceRange: "",
    rating: "",
  },
};

const slice = createSlice({
  name: "productRestock",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },
    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    saveProductsToLookFor(state, action) {
      state.productsToLookFor = action.payload;
    },
    updateAddToExpenses(state, action) {
      state.addToExpenses = action.payload;
    },
    updateExternalInvoice(state, action) {
      state.externalInvoice = action.payload;
    },
    updateExternalOrderId(state, action) {
      state.externalOrderId = action.payload;
    },
    updateSupplier(state, action) {
      state.supplier = action.payload;
    },
    updateExpensePaymentType(state, action) {
      state.expensePaymentType = action.payload;
    },
    deleteProducts(state, action) {
      const idsToDelete = action.payload;
      state.newProducts = state.newProducts.filter(
        (product) => !idsToDelete.includes((product as any).id)
      );
      state.restockProducts = state.restockProducts.filter(
        (product) => !idsToDelete.includes((product as any).id)
      );
    },

    // GET PRODUCTS
    getProductsSuccess(state, action) {
      const products = action.payload.products;
      let dbProducts = action.payload.dbProducts.items;

      dbProducts = dbProducts.map((item) => {
        return {
          ...item,
          batches: [],
        };
      });
      products.forEach((product) => {
        const objIndex = dbProducts.findIndex(
          (obj) => obj.barcode === product.barcode
        );

        if (objIndex === -1) {
          state.newProducts = [
            ...state.newProducts,
            {
              ...product,
              id: faker.random.uuid(),
              typeOfRestock: RestockType.new,
              batches: [],
              batch: false,
              totalPriceWithIVAAndIEPS:
                product.price + product.IVA + product.IEPS,
            },
          ];
        } else {
          state.restockProducts = [
            ...state.restockProducts,
            {
              ...dbProducts[objIndex],
              cost: product.cost,
              quantity: product.quantity,
              typeOfRestock: RestockType.restock,
              changeInCost: dbProducts[objIndex].cost - product.cost,
              IVA: dbProducts[objIndex].IVA * 100,
              IEPS: dbProducts[objIndex].IEPS * 100,
              discount: dbProducts[objIndex].discount * 100,
            },
          ];
        }
      });
      state.isLoading = false;
    },

    updateProduct(state, action) {
      state.isLoading = false;
      const id = action.payload.id;
      const featureToEdit = action.payload.field;
      const newValue = action.payload.value;
      const objRestockIndex = state.restockProducts.findIndex(
        (obj) => obj.id === id
      );
      if (objRestockIndex !== -1) {
        const productToEdit = state.restockProducts[objRestockIndex];
        productToEdit[featureToEdit] = newValue;
        state.restockProducts = [
          ...state.restockProducts.slice(0, objRestockIndex),
          productToEdit,
          ...state.restockProducts.slice(objRestockIndex + 1),
        ];
      } else {
        const newProductIndex = state.newProducts.findIndex(
          (obj) => (obj as any).id === id
        );
        const productToEdit = state.newProducts[newProductIndex];
        productToEdit[featureToEdit] = newValue;
        state.newProducts = [
          ...state.newProducts.slice(0, newProductIndex),
          productToEdit,
          ...state.newProducts.slice(newProductIndex + 1),
        ];
      }
    },
    addRestockProductFromSearchBar(state, action) {
      state.isLoading = false;
      const dbProduct = action.payload.dbProduct.items[0];
      const updateProduct = action.payload
        .product as CreateOrUpdateGenericProductDto;
      if (!updateProduct.id) {
        return;
      }
      const newProduct: CreateOrUpdateGenericProductDto = {
        ...updateProduct,
        batches: [],
        typeOfRestock: RestockType.restock,
        totalPriceWithIVAAndIEPS:
          updateProduct.price +
          updateProduct.price * dbProduct.IVA +
          updateProduct.price * dbProduct.IEPS,
        changeInCost: updateProduct.cost - dbProduct.cost,
      };
      state.restockProducts = [...state.restockProducts, newProduct];
    },
    updateRestockProducts(state, action) {
      state.isLoading = false;
      const dbProduct = action.payload.dbProduct.items[0];
      const updateProduct = action.payload
        .product as CreateOrUpdateGenericProductDto;
      if (!updateProduct.id) {
        return;
      }
      const newProduct: CreateOrUpdateGenericProductDto = {
        ...updateProduct,
        batches: [],
        typeOfRestock: RestockType.restock,
        totalPriceWithIVAAndIEPS:
          updateProduct.price +
          updateProduct.price * dbProduct.IVA +
          updateProduct.price * dbProduct.IEPS,
        changeInCost: updateProduct.cost - dbProduct.cost,
      };
      const objIndex = state.restockProducts.findIndex(
        (obj) => obj.id === updateProduct.id
      );
      if (objIndex === -1) {
        state.restockProducts = [...state.restockProducts, newProduct];
      }
    },

    //  SORT & FILTER PRODUCTS
    sortByProducts(state, action) {
      state.sortBy = action.payload;
    },

    filterProducts(state, action) {
      state.filters.gender = action.payload.gender;
      state.filters.category = action.payload.category;
      state.filters.colors = action.payload.colors;
      state.filters.priceRange = action.payload.priceRange;
      state.filters.rating = action.payload.rating;
    },

    resetAll(state) {
      state.newProducts = [];
      state.restockProducts = [];
      state.addToExpenses = false;
      state.expensePaymentType = null;
      state.supplier = null;
      state.externalInvoice = "";
      state.externalOrderId = "";
    },
    getProductBatches(state, action) {
      let id = action.payload;
      const newProductIndex = state.newProducts.findIndex(
        (obj) => obj.id === id
      );
      if (newProductIndex !== -1) {
        state.selectedBatches = [...state.newProducts[newProductIndex].batches];
      } else {
        const restockProductIndex = state.restockProducts.findIndex(
          (obj) => obj.id === id
        );
        if (restockProductIndex !== -1) {
          state.selectedBatches = [
            ...state.restockProducts[restockProductIndex].batches,
          ];
        }
      }
    },
    updateProductBatches(state, action) {
      let { index, field, value } = action.payload;
      const batchToEdit = state.selectedBatches[index];
      batchToEdit[field] = value;
      state.selectedBatches = [
        ...state.selectedBatches.slice(0, index),
        batchToEdit,
        ...state.selectedBatches.slice(index + 1),
      ];
    },
    updateAllProductBatches(state, action) {
      let batches = action.payload;
      state.selectedBatches = [...batches];
    },
    saveProductBatches(state, action) {
      let id = action.payload;
      const objRestockIndex = state.restockProducts.findIndex(
        (obj) => obj.id === id
      );
      let newQuantity = 0;
      state.selectedBatches.forEach((batch) => (newQuantity += batch.quantity));
      if (objRestockIndex !== -1) {
        let productToEdit = state.restockProducts[objRestockIndex];
        productToEdit.quantity = newQuantity;
        productToEdit.batches = [...state.selectedBatches];
        productToEdit.batch = true;
        state.restockProducts = [
          ...state.restockProducts.slice(0, objRestockIndex),
          { ...productToEdit },
          ...state.restockProducts.slice(objRestockIndex + 1),
        ];
      } else {
        const newProductIndex = state.newProducts.findIndex(
          (obj) => (obj as any).id === id
        );
        if (newProductIndex !== -1) {
          let productToEdit = state.newProducts[newProductIndex];
          productToEdit.quantity = newQuantity;
          console.log("selectedBatches", newQuantity);
          productToEdit.batches = state.selectedBatches;
          productToEdit.batch = true;
          state.newProducts = [
            ...state.newProducts.slice(0, newProductIndex),
            { ...productToEdit },
            ...state.newProducts.slice(newProductIndex + 1),
          ];
        }
      }
      state.selectedBatches = [];
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  sortByProducts,
  filterProducts,
} = slice.actions;


export const selectQueryString = (products: CreateGenericProductDto[]) => {
  const barcodes = products.map(product => product.barcode)
  return '?barcode=' + barcodes.join()
};

// ---------------------------------------------------------------------

export function updateProductsFromGrid(product: any) {
  return (dispatch) => {
    try {
      dispatch(slice.actions.updateProduct(product));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function addNewProductFromSearchbar(product: CreateOrUpdateGenericProductDto) {
  return async (dispatch) => {
    //dispatch(slice.actions.startLoading());
    // try {
    //   const response = await axios.get(`${apiURl}/generic-product-pharmacy-db?_id=${product.id}`);
    //   dispatch(slice.actions.updateRestockProducts({ dbProduct: response.data, product }));
    // } catch (error) {
    //   dispatch(slice.actions.hasError(error));
    // }
  };
}

export function addRestockProductFromSearchbar(product: CreateOrUpdateGenericProductDto) {
  return async (dispatch) => {
    //dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${apiURl}/generic-product-pharmacy-db?_id=${product.id}`);
      dispatch(slice.actions.updateRestockProducts({ dbProduct: response.data, product }));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}



// ----------------------------------------------------------------------

export interface CreateOrUpdateGenericProductDto extends CreateGenericProductDto {
  typeOfRestock: RestockType;
  batches: RestockBatch[];
  isBillable: boolean;
  changeInCost?: number;
  id?: string;
}


export function getProductsAndSeeIfNewOrRestock(products: CreateGenericProductDto[]) {
  return async (dispatch) => {
    //dispatch(slice.actions.startLoading());
    try {
      const queryString = selectQueryString(products)
      const response = await axios.get(`${apiURl}/generic-product-pharmacy-db${queryString}`);
      dispatch(slice.actions.getProductsSuccess({ dbProducts: response.data, products }));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}


// ----------------------------------------------------------------------

export function emptyTable() {
  return async (dispatch) => {
    dispatch(slice.actions.resetAll());
  };
}

// ----------------------------------------------------------------------

export function onChangeAddToExpenses(addToExpense: boolean) {
  return async (dispatch) => {
    dispatch(slice.actions.updateAddToExpenses(addToExpense));
  };
}

// ----------------------------------------------------------------------

export function onChangeSupplier(id: string) {
  return async (dispatch) => {
    dispatch(slice.actions.updateSupplier(id));
  };
}

// ----------------------------------------------------------------------

export function onChangeExpensePaymentType(expensementType: ExpensePaymentType) {
  return async (dispatch) => {
    dispatch(slice.actions.updateExpensePaymentType(expensementType));
  };
}

// ----------------------------------------------------------------------

export function onChangeExternalInvoice(invoice: string) {
  return async (dispatch) => {
    dispatch(slice.actions.updateExternalInvoice(invoice));
  };
}

// ----------------------------------------------------------------------

export function onChangeExternalOrderId(orderId: string) {
  return async (dispatch) => {
    dispatch(slice.actions.updateExternalOrderId(orderId));
  };
}

// ----------------------------------------------------------------------



export function deleteProducts(ids: string[]) {
  return (dispatch) => {
    //dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.deleteProducts(ids));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------



export function getProductBatches(id: string) {
  return (dispatch) => {
    //dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.getProductBatches(id));
    } catch (error) {
      console.log('errrrr', error)

      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------


export function updateProductBatches({ index, field, value }) {
  return (dispatch) => {
    //dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.getProductBatches({ index, field, value }));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
// ----------------------------------------------------------------------


export function saveProductBatches(id) {
  return (dispatch) => {
    //dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.saveProductBatches(id));
    } catch (error) {
      console.log('errrrr', error)
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function updateAllProductBatches(newBatches: RestockBatch) {
  return (dispatch) => {
    //dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.updateAllProductBatches(newBatches));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
//



