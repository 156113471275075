

import { createSlice } from '@reduxjs/toolkit';
import { DateRange } from './generic-product';
import { IBatch } from 'lib/interfaces/batch';
import { getBatchesFromBack } from 'lib/api/batch';

// ----------------------------------------------------------------------

export interface IFilterBatches {
    search?: string,
    pharmacy: string,
    barcode?: string,
    product?: string,
    onlyAvailable?: boolean,
    expiryDate?: string,
    dateRange?: {
        startDate: Date,
        endDate: Date,
    },
    pagination: {
        limit: number,
        skip: number
    }

}

const initialState = {
    batchs: [] as IBatch[],
    batch: {} as IBatch,
    error: false,
    isLoading: false,
    batchCount: 0,
    sortBy: null,
    filters: {
        gender: [],
        category: 'All',
        colors: [],
        priceRange: '',
        rating: '',
    },
};


const slice = createSlice({
    name: 'batch',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },

        // GET batchS
        getbatchsSuccess(state, action) {
            state.isLoading = false;
            state.batchs = action.payload.items;
            state.batchCount = action.payload.count;
        },
        // GET batchS
        getbatchsuccess(state, action) {
            state.isLoading = false;
            state.batch = action.payload;

        },

        //  SORT & FILTER PRODUCTS
        sortBybatchs(state, action) {
            state.sortBy = action.payload;
        },

        filterbatchs(state, action) {
            state.filters.gender = action.payload.gender;
            state.filters.category = action.payload.category;
            state.filters.colors = action.payload.colors;
            state.filters.priceRange = action.payload.priceRange;
            state.filters.rating = action.payload.rating;
        },


    },
});

// Reducer
export default slice.reducer;

// Actions
export const {
    filterbatchs,
} = slice.actions;


function getSearchFilter(search: string): Map<string, any> {
    let filter: any = {};
    if (!!search) {
        filter = {
            $or: [{ batchNo: search }, { barcode: search }],
        };
    }
    return filter;
}

function getDateFilter(dateRange: DateRange): Map<string, any> {
    if (!dateRange) {
        return
    }
    let filter = {} as any;
    if (!!dateRange.startDate) {
        filter = {
            ...filter,
            createdAt: !!filter?.createdAt
                ? { ...filter?.createdAt, $gte: dateRange.startDate }
                : { $gte: dateRange.startDate },
        };
    }
    if (!!dateRange.endDate) {
        filter = {
            ...filter,
            createdAt: !!filter?.createdAt ? { ...filter?.createdAt, $lt: dateRange.endDate } : { $lt: dateRange.endDate },
        };
    }
    return filter;
}

const getAvailable = (available: boolean) => {
    let filter = {} as any;
    if (available) {
        filter = { quantity: { $gt: 0 } }
    }
    return filter;
}

const getBarcode = (barcode: string) => {
    let filter = {} as any;
    if (barcode) {
        filter = { barcode }
    }
    return filter;
}

export const getExpiryDate = (expiryDate: string) => {
    let filter = {} as any;
    if (expiryDate) {
        filter = {
            expiryDate: { $lt: expiryDate }
        };
    }
    return filter;
}

const getProduct= (product: string) => {
    let filter = {} as any;
    if (product) {
        filter = { product }
    }
    return filter;
}

export const selectQueryString = (filters: IFilterBatches) => {
    let queryString = '?';
    const filter = {
        ...getAvailable(filters.onlyAvailable),
        ...getSearchFilter(filters.search),
        ...getBarcode(filters.barcode),
        ...getDateFilter(filters.dateRange),
        ...getExpiryDate(filters.expiryDate),
        ...getProduct(filters.product),
        pharmacy: filters.pharmacy
    };
    queryString += Object.keys(filter).length > 0 ? `filter=${JSON.stringify(filter)}&` : '';
    queryString += `limit=${filters.pagination.limit}&skip=${filters.pagination.skip}`;
    /*
    if (!!state.sorting.active) {
      queryString += `&sort=${state.sorting.direction === 'asc' ? '' : '-'}${state.sorting.active}`;
    } */
    return queryString;
};


// ----------------------------------------------------------------------

export function getBatchs(query: IFilterBatches) {
    return async (dispatch) => {
        dispatch(slice.actions.startLoading());
        try {
            const queryString = selectQueryString(query)
            const response = await getBatchesFromBack(queryString)
            dispatch(slice.actions.getbatchsSuccess(response));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function getBatch(query: IFilterBatches) {
    return async (dispatch) => {
        dispatch(slice.actions.startLoading());
        try {

            const queryString = selectQueryString(query)
            const response = await getBatchesFromBack(queryString)
            dispatch(slice.actions.getbatchsuccess(response.items[0]));
        } catch (error) {
            console.error(error);
            dispatch(slice.actions.hasError(error));
        }
    };
}




export function getBatchById(id) {
    return async (dispatch) => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await getBatchesFromBack(`?_id=${id}`)
            dispatch(slice.actions.getbatchsuccess(response.items[0]));
        } catch (error) {
            console.error(error);
            dispatch(slice.actions.hasError(error));
        }
    };
}


