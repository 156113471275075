import React from 'react';
import {
  Router,
  /* Switch,
   Route,
   Link,
   useRouteMatch,
   useParams, */
} from 'react-router-dom';
/*
import Appbar from './components/Appbar';
import LoginPage from './pages/login';

import { useContext } from 'react';
import { AuthContext } from './contexts/auth/auth.context';
import { ThemeProvider } from '@material-ui/core'; */
import { GuardProvider } from 'react-router-guards';
import { ThemeConfig } from './theme';
import { Provider } from 'react-redux';
import { createBrowserHistory } from 'history';
import { store } from './redux/store';
import routes, { renderRoutes } from './routes';
import ScrollToTop from './components/ScrollToTop';
import { ClearCacheProvider } from 'react-clear-cache';
// import LoadingScreen from './components/LoadingScreen';
// import GoogleAnalytics from './components/GoogleAnalytics';
// import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import NotistackProvider from './components/NotistackProvider';
// import { PersistGate } from 'redux-persist/lib/integration/react';
// import LocalizationProvider from '@material-ui/lab/LocalizationProvider';
import './App.scss';

// Using for Auth (Check doc https://minimals.cc/docs/authentication)
// import JwtProvider from './components/Auth/JwtProvider';
// import FirebaseProvider from './components/Auth/FirebaseProvider';

// ----------------------------------------------------------------------

const history = createBrowserHistory();
const isBrowser = typeof window !== 'undefined';


export default function App() {
  // const {
  //   authState: { isAuthenticated },
  // } = useContext<any>(AuthContext);


  const requireLogin = (to: any, from: any, next: any) => {
    const isAuthenticated = isBrowser && !!localStorage.getItem(process.env.REACT_APP_ACCESS_TOKEN || 'medify_access_token')
    if (isAuthenticated) {
      next();
    } else {
      next.redirect('/auth/login');
    }
  };

  // return (
  //   <Router>
  //     <GuardProvider guards={[requireLogin]}>
  //       <Appbar>
  //         <div>
  //           <Switch>
  //             <Route path="/about">
  //               <About />
  //             </Route>
  //             <Route path="/topics">
  //               <Topics />
  //             </Route>
  //             <Route path="/login">
  //               <LoginPage />
  //             </Route>
  //             <GuardedRoute path="/">
  //               <Home />
  //             </GuardedRoute>
  //           </Switch>
  //         </div>
  //       </Appbar>
  //     </GuardProvider>
  //   </Router>
  // );

  return (
    <ClearCacheProvider duration={5000}>
      <Provider store={store}>
        {/* <PersistGate loading={<LoadingScreen />} persistor={persistor}> */}
        <ThemeConfig>
          {/* <LocalizationProvider dateAdapter={AdapterDateFns}> */}
          <NotistackProvider>

            <Router {...{ history }}>
              <GuardProvider guards={[requireLogin]}>
                <>
                  {/* <JwtProvider> */}

                  <ScrollToTop />
                  {/* <GoogleAnalytics /> */}
                  {renderRoutes(routes)}
                  {/* </JwtProvider> */}
                </>
              </GuardProvider>
            </Router>

          </NotistackProvider>
          {/* </LocalizationProvider> */}
        </ThemeConfig>
        {/* </PersistGate> */}
      </Provider>
    </ClearCacheProvider>
  );
}
