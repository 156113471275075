

import { createSlice } from '@reduxjs/toolkit';
import { DateRange } from './generic-product';
import { IFilter } from 'redux/shared/interfaces';
import { getPurchasesFromBack, getPurchasesResultFromBack } from '../../lib/api/cash-register-purchase'
import { getExpensesFromBack, getExpensesResultFromBack } from '../../lib/api/cash-register-expense'
import { ICashRegisterPurchase } from 'lib/interfaces/cash-register-purchase';
import { ICashRegisterExpense } from 'lib/interfaces/cash-register-expense';
import moment from "moment"
import { IKPI } from 'lib/interfaces/kpi';

// ----------------------------------------------------------------------


export enum StatisticsType {
    CashRegisterPurchase = 'CashRegisterPurchase',
    CashRegisterExpense = 'CashRegisterExpense'
}

export interface IStatiticsReducer {
    purchases: ICashRegisterPurchase[]
    expenses: ICashRegisterExpense[]
    selectedType: StatisticsType
    error: boolean,
    to: Date,
    from: Date,
    isLoading: boolean,
    resultsExpenses: IKPI,
    resultsPurchases: IKPI
}

const initialState: IStatiticsReducer = {
    purchases: [] as ICashRegisterPurchase[],
    expenses: {} as ICashRegisterExpense[],
    resultsPurchases: {
        totalCash: 0,
        totalCard: 0,
        margin: 0,
        total: 0,
        count: 0,
        array:[]
    },
    resultsExpenses: {
        totalCash: 0,
        totalCard: 0,
        margin: 0,
        total: 0,
        count: 0,
        array: []
    },
    selectedType: StatisticsType.CashRegisterPurchase,
    error: false,
    to: moment()
        .hours(0)
        .minutes(0)
        .seconds(0)
        .toDate(),
    from: moment()
        .subtract(30, 'days')
        .toDate(),
    isLoading: false,


};


const slice = createSlice({
    name: 'statistics',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },
        changeType(state, action) {
            state.selectedType = action.payload;
        },
        changeToDate(state, action) {
            state.to = action.payload;
        },
        changeFromDate(state, action) {
            state.from = action.payload;
        },
        // GET PURCHASES
        getPuchasesSuccess(state, action) {
            state.isLoading = false;
            state.purchases = action.payload.items;
        },
        // GET EXPENSES
        getExpensesSuccess(state, action) {
            state.isLoading = false;
            state.expenses = action.payload.items;
        },
        getResultsExpensesSuccess(state, action) {
            state.resultsExpenses = action.payload;
        },
        getResultsPurchasesSuccess(state, action) {
            state.resultsPurchases = action.payload;
        }
    },
});

// Reducer
export default slice.reducer;

// Actions
// export const {
//     ,
// } = slice.actions;


function getDateFilter(dateRange: DateRange): Map<string, any> {
    if (!dateRange) {
        return
    }
    let filter = {} as any;
    if (!!dateRange.startDate) {
        filter = {
            ...filter,
            createdAt: !!filter?.createdAt
                ? { ...filter?.createdAt, $gte: dateRange.startDate }
                : { $gte: dateRange.startDate },
        };
    }
    if (!!dateRange.endDate) {
        filter = {
            ...filter,
            createdAt: !!filter?.createdAt ? { ...filter?.createdAt, $lt: dateRange.endDate } : { $lt: dateRange.endDate },
        };
    }
    return filter;
}

export const selectQueryString = (filters: IFilter) => {
    let queryString = '?';
    const filter = {
        // ...getSearchFilter(filters.search),
        ...getDateFilter(filters.dateRange),
        pharmacy: filters.pharmacy
    };
    queryString += Object.keys(filter).length > 0 ? `filter=${JSON.stringify(filter)}&` : '';
    queryString += `limit=${filters.pagination.limit}&skip=${filters.pagination.skip || 0}`;
    /*
    if (!!state.sorting.active) {
      queryString += `&sort=${state.sorting.direction === 'asc' ? '' : '-'}${state.sorting.active}`;
    } */
    return queryString;
};


// ----------------------------------------------------------------------

export function getPurchases(query: IFilter) {
    return async (dispatch) => {
        dispatch(slice.actions.startLoading());
        try {
            const queryString = selectQueryString(query)
            const response = await getPurchasesFromBack(queryString)
            dispatch(slice.actions.getPuchasesSuccess(response));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function getExpenses(query: IFilter) {
    return async (dispatch) => {
        dispatch(slice.actions.startLoading());
        try {
            const queryString = selectQueryString(query)
            const response = await getExpensesFromBack(queryString)
            dispatch(slice.actions.getExpensesSuccess(response));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------


export function setType(type: StatisticsType) {
    return (dispatch) => {
        dispatch(slice.actions.changeType(type));
    };
}

// ----------------------------------------------------------------------

export function setTo(date: Date) {
    return (dispatch) => {
        dispatch(slice.actions.changeToDate(date));
    };
}

// ----------------------------------------------------------------------

export function setFrom(date: Date) {
    return (dispatch) => {
        dispatch(slice.actions.changeFromDate(date));
    };
}

// ----------------------------------------------------------------------

export function setFromAndTo(from: Date, to: Date) {
    return (dispatch) => {
        dispatch(slice.actions.changeFromDate(from));
        dispatch(slice.actions.changeToDate(to));
    };
}


export function  getResultsKPI(startDate: Date, endDate: Date){
    return async (dispatch) => {
        const resultExpenses = await getExpensesResultFromBack(startDate, endDate)
        const resultPurchases = await getPurchasesResultFromBack(startDate, endDate)
        dispatch(slice.actions.getResultsExpensesSuccess(resultExpenses))
        dispatch(slice.actions.getResultsPurchasesSuccess(resultPurchases))
    }
}

