import NavItem from "./NavItem";
import MenuLinks, { navConfigAdmin } from "./config";
// import PropTypes from 'prop-types';
import Logo from "../../../components/Logo";
import useAuth from "../../../hooks/useAuth";
import React, { useEffect } from "react";
import MyAvatar from "../../../components/MyAvatar";
import Scrollbars from "../../../components/Scrollbars";
import { useDispatch, useSelector } from "react-redux";
import { PATH_APP } from "../../../routes/paths";
import { Link as RouterLink, useLocation, matchPath } from "react-router-dom";
import { makeStyles, alpha } from "@material-ui/core/styles";
import {
  Box,
  Link,
  List,
  Drawer,
  Hidden,
  Typography,
  ListSubheader,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { PATH_PAGE } from "routes/paths";
import { useSnackbar } from "notistack";
import Pusher from "pusher-js";
import { getOrders } from "redux/slices/order";
import { OrderStatusType } from "lib/interfaces/order";
import { IRootState } from "redux/rootReducer";

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;
var mobile = require("is-mobile");

const isSupported = () => "Notification" in window;

Pusher.logToConsole = true;
var pusher = new Pusher("58b5bc405ba429d5abe4", {
  cluster: "us2",
});

const useStyles = makeStyles((theme: any) => {
  const isLight = theme.palette.mode === "light";

  return {
    drawer: {
      [theme.breakpoints.up("lg")]: {
        flexShrink: 0,
        width: DRAWER_WIDTH,
      },
    },
    drawerPaper: {
      width: DRAWER_WIDTH,
      background: theme.palette.background.default,
    },
    subHeader: {
      ...theme.typography.overline,
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(2),
      paddingLeft: theme.spacing(5),
      color: theme.palette.text.primary,
    },
    account: {
      display: "flex",
      alignItems: "center",
      padding: theme.spacing(2, 2.5),
      margin: theme.spacing(1, 2.5, 5),
      borderRadius: theme.shape.borderRadiusSm,
      background: theme.palette.grey[isLight ? 200 : 800],
    },
    doc: {
      padding: theme.spacing(2.5),
      borderRadius: theme.shape.borderRadiusMd,
      backgroundColor: isLight
        ? alpha(theme.palette.primary.main, 0.08)
        : theme.palette.primary.lighter,
    },
  };
});

// ----------------------------------------------------------------------

function reduceChild({ array, item, pathname, level }) {
  const key = item.href + level;

  if (item.items) {
    const match = matchPath(pathname, {
      path: item.href,
      exact: false,
    });

    array = [
      ...array,
      <NavItem
        key={key}
        level={level}
        icon={item.icon}
        info={item.info}
        href={item.href}
        title={item.title}
        open={Boolean(match)}
      >
        {renderNavItems({
          pathname,
          level: level + 1,
          items: item.items,
        })}
      </NavItem>,
    ];
  } else {
    array = [
      ...array,
      item.hiddenInMobile ? (
        <Hidden mdDown>
          <NavItem
            key={key}
            level={level}
            href={item.href}
            icon={item.icon}
            info={item.info}
            title={item.title}
          />
        </Hidden>
      ) : (
        <NavItem
          key={key}
          level={level}
          href={item.href}
          icon={item.icon}
          info={item.info}
          title={item.title}
        />
      ),
    ];
  }
  return array;
}

function renderNavItems({ items, pathname, level = 0 }) {
  return (
    <List disablePadding>
      {items.reduce(
        (array, item) => reduceChild({ array, item, pathname, level }),
        []
      )}
    </List>
  );
}

// NavBar.propTypes = {
//   onCloseNav: PropTypes.func,
//   isOpenNav: PropTypes.bool,
// };

const OrderNumberCirle = ({ number }) => {
  return (
    <div
      style={{
        borderRadius: "50%",
        width: "34px",
        height: "34px",
        padding: "3px",
        background: "red",
        border: "3px solid red",
        color: "white",
        fontWeight: "bolder",
        textAlign: "center",
      }}
    >
      {number}
    </div>
  );
};

function NavBar({ isOpenNav, onCloseNav }) {
  const classes = useStyles();
  const { pathname } = useLocation();
  const { cashier } = useAuth();
  const history = useHistory();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  const actionCloseSnackBar = (snackbarId) => (
    <>
      <button
        onClick={() => {
          closeSnackbar(snackbarId);
        }}
      >
        Cerrar
      </button>
    </>
  );

  const pharmacy = JSON.parse(localStorage.getItem("medify_pharmacy"));
  let { newOrdersCount } = useSelector((state: IRootState) => state.order);

  const { isAdmin } = useSelector((state: IRootState) => state.authJwt);

  useEffect(() => {
    if (isOpenNav && onCloseNav) {
      onCloseNav();
    }
    // eslint-disable-next-line
  }, [pathname]);

  const suscribeToChannel = async () => {
    try {
      var channel = pusher.subscribe(`pharmacy-channel`);
      if (isSupported) Notification.requestPermission();
      channel.bind(`pharmacy-${pharmacy.id}`, function (data) {
        if (isSupported)
          new Notification("Medify", {
            body: data.message,
            icon: "https://medify.mx/wp-content/uploads/2020/07/Medify-logo2.png",
          });
        enqueueSnackbar("Nueva Orden", {
          variant: "warning",
          persist: true,
          action: actionCloseSnackBar,
        });
        dispatch(
          getOrders(
            {
              pharmacy: pharmacy.id,
              pagination: {
                limit: 1000,
                skip: 0,
              },
            },
            OrderStatusType.Reserved
          )
        );
      });
    } catch (err) {
      enqueueSnackbar(err.message, { variant: "error" });
      // enqueueSnackbar("Error cargando notifiaciones", { variant: "error" });
    }
  };

  useEffect(() => {
    if (pharmacy?.id && !mobile()) {
      suscribeToChannel();
    }
    //eslint-disable-next-line
  }, [pharmacy]);

  if (!pharmacy?.name) {
    history.push(PATH_PAGE.auth.login);
    return <div />;
  }

  const renderContent = (
    <Scrollbars>
      <Box {...{ sx: { px: 2.5, py: 3 } }}>
        <RouterLink to="/">
          <Logo className="nav-logo" />
        </RouterLink>
      </Box>

      <Link
        underline="none"
        component={RouterLink}
        to={PATH_APP.management.user.account}
      >
        <div className={classes.account}>
          <MyAvatar
            isPharmacy={true}
            name={pharmacy["name"]}
            image={pharmacy["logoUrl"]}
          />
          <Box {...{ sx: { ml: 2 } }}>
            <Typography
              variant="subtitle2"
              {...{ sx: { color: "text.primary" } }}
            >
              {cashier?.firstName}
            </Typography>
            <Typography
              variant="body2"
              {...{ sx: { color: "text.secondary" } }}
            >
              {!isAdmin && "Cajero"}
            </Typography>
          </Box>
        </div>
      </Link>
      <List
        disablePadding
        key={"order-listing"}
        subheader={
          <ListSubheader
            disableSticky
            disableGutters
            className={classes.subHeader}
          >
            Ordenes
          </ListSubheader>
        }
      >
        {renderNavItems({
          items: [
            {
              title: "Ordenes Nuevas",
              icon: <OrderNumberCirle number={newOrdersCount} />,
              href: `${PATH_APP.management.eCommerce.orders}`,
            },
          ],
          pathname: `${PATH_APP.management.eCommerce.orders}`,
        })}
      </List>

      {MenuLinks.map((list) => {
        return (
          <List
            disablePadding
            key={list.subheader}
            subheader={
              <ListSubheader
                disableSticky
                disableGutters
                className={classes.subHeader}
              >
                {list.subheader}
              </ListSubheader>
            }
          >
            {renderNavItems({
              items: list.items,
              pathname: pathname,
            })}
          </List>
        );
      })}

      {isAdmin &&
        navConfigAdmin.map((list) => (
          <List
            disablePadding
            key={list.subheader}
            subheader={
              <ListSubheader
                disableSticky
                disableGutters
                className={classes.subHeader}
              >
                {list.subheader}
              </ListSubheader>
            }
          >
            {renderNavItems({
              items: list.items,
              pathname: pathname,
            })}
          </List>
        ))}
      <br />
      <br />
      <br />
    </Scrollbars>
  );

  return (
    <nav className={classes.drawer}>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          open={isOpenNav}
          variant="temporary"
          onClose={onCloseNav}
          classes={{ paper: classes.drawerPaper }}
        >
          {renderContent}
        </Drawer>
      </Hidden>
      <Hidden lgDown>
        <Drawer
          open
          anchor="left"
          variant="persistent"
          classes={{ paper: classes.drawerPaper }}
        >
          {renderContent}
        </Drawer>
      </Hidden>
    </nav>
  );
}

export default NavBar;
