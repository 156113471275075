import clsx from "clsx";
import PropTypes from "prop-types";
import ThemeMode from "./ThemeMode";
import { Icon } from "@iconify/react";
import React, { useState } from "react";
import { useSnackbar } from "notistack";
import { useDispatch } from "react-redux";
import useIsMountedRef from "../../../hooks/useIsMountedRef";
import closeFill from "@iconify-icons/eva/close-fill";
import settings2Fill from "@iconify-icons/eva/settings-2-fill";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Drawer, Divider, Typography, Button } from "@material-ui/core";
import { MIconButton } from "../../../theme";
import { logout } from "redux/slices/authJwt";
import { useHistory } from "react-router-dom";

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 260;

const useStyles = makeStyles(
  (theme: any) =>
    ({
      root: {},
      drawer: {
        zIndex: "1999 !important",
      },
      drawerPaper: {
        width: DRAWER_WIDTH,
      },
    } as any)
);

// ----------------------------------------------------------------------

Settings.propTypes = {
  className: PropTypes.string,
};

function Settings({ className }) {
  const isMountedRef = useIsMountedRef();
  const dispatch = useDispatch();
  const classes: any = useStyles();
  const [open, setOpen] = useState(false);
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const handleLogout = async () => {
    try {
      dispatch(logout());
      if (isMountedRef.current) {
        history.push("/");
        // handleClose();
      }
    } catch (error) {
      console.log(error);
      enqueueSnackbar("Imposible cerrar sesión", {
        variant: "error",
        autoHideDuration: 3000,
      });
    }
  };

  const handleOpenSettings = () => {
    setOpen(true);
  };

  const handleCloseSettings = () => {
    setOpen(false);
  };

  return (
    <div className={clsx(classes.root, className)}>
      <MIconButton onClick={handleOpenSettings}>
        <Icon icon={settings2Fill} width={20} height={20} />
      </MIconButton>

      <Drawer
        open={open}
        anchor="right"
        onClose={handleCloseSettings}
        classes={{
          root: classes.drawer,
          paper: classes.drawerPaper,
        }}
      >
        <Box
          {...{
            sx: {
              py: 2,
              pr: 1,
              pl: 2.5,
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            },
          }}
        >
          <Typography variant="subtitle1">Configuración</Typography>
          <MIconButton onClick={handleCloseSettings}>
            <Icon icon={closeFill} width={20} height={20} />
          </MIconButton>
        </Box>
        <Divider />

        <Box {...{ sx: { p: 2.5 } }}>
          <Typography variant="subtitle2" gutterBottom>
            Tema
          </Typography>
          <ThemeMode />

          <Box {...({ sx: { my: 3 } } as any)} />

          {/* <Typography variant="subtitle2" gutterBottom>
            Direction
          </Typography>
          <ThemeDirection /> */}
        </Box>
        <Box {...{ sx: { p: 2, pt: 1.5 } }}>
          <Button
            fullWidth
            color="inherit"
            variant="outlined"
            onClick={handleLogout}
          >
            Cerrar sesión
          </Button>
        </Box>
      </Drawer>
    </div>
  );
}

export default Settings;
