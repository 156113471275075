import clsx from 'clsx';
import React from 'react';
// import PropTypes from 'prop-types';
import { motion } from 'framer-motion';
import { varWrapEnter } from './';
import { makeStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';

// ----------------------------------------------------------------------

const useStyles = makeStyles((theme) => ({
  root: {},
}));

// ----------------------------------------------------------------------
export interface IMotionContainerProps {
  open: boolean;
  children: React.ReactNode;
  [key: string]: any;
}
// MotionContainer.propTypes = {
//   open: PropTypes.bool.isRequired,
//   children: PropTypes.node,
// };

function MotionContainer({ open, children, className, ...other }: IMotionContainerProps) {
  const classes = useStyles();

  return (
    <Box
      component={motion.div}
      className={clsx(classes.root, className)}
      {...{
        initial: false,
        animate: open ? 'animate' : 'exit',
        variants: varWrapEnter,
      }}
      {...other}
    >
      {children}
    </Box>
  );
}

export default MotionContainer;
