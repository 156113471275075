

import { createSlice } from '@reduxjs/toolkit';
import { DateRange } from './generic-product';
import { IFilter } from 'redux/shared/interfaces';
import { getPurchaseProductsFromBack } from '../../lib/api/cash-register-purchase-product'
import { ICashRegisterPurchaseProduct } from 'lib/interfaces/cash-register-purchase-product';
// ----------------------------------------------------------------------


const initialState = {
    products: [] as ICashRegisterPurchaseProduct[],
    product: {} as ICashRegisterPurchaseProduct,
    error: false,
    isLoading: false,
    productCount: 0,
    sortBy: null,
    filters: {
        gender: [],
        category: 'All',
        colors: [],
        priceRange: '',
        rating: '',
    },
};


const slice = createSlice({
    name: 'purchaseProduct',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },

        // GET PURCHASES
        getPuchasesSuccess(state, action) {
            state.isLoading = false;
            state.products = action.payload.items;
            state.productCount = action.payload.count;
        },
        // GET PURCHASES
        getPuchaseSuccess(state, action) {
            state.isLoading = false;
            state.product = action.payload;

        },

        //  SORT & FILTER PRODUCTS
        sortByPurchases(state, action) {
            state.sortBy = action.payload;
        },

        //  SORT & FILTER PRODUCTS
        cleanPurchase(state) {
            state.product = {} as ICashRegisterPurchaseProduct;
        },

        filterProducts(state, action) {
            state.filters.gender = action.payload.gender;
            state.filters.category = action.payload.category;
            state.filters.colors = action.payload.colors;
            state.filters.priceRange = action.payload.priceRange;
            state.filters.rating = action.payload.rating;
        },
    },
});

// Reducer
export default slice.reducer;

// Actions
export const {
    filterProducts,
} = slice.actions;


function getSearchFilter(search: string): Map<string, any> {
    let filter: any = {};
    if (!!search) {
        filter = {
            $or: [{ name: { $regex: search, $options: 'i' } }, { brand: { $regex: search, $options: 'i' } }, { barcode: { $regex: search, $options: 'i' } }],
        };
    }
    return filter;
}

function getDateFilter(dateRange: DateRange): Map<string, any> {
    if (!dateRange) {
        return
    }
    let filter = {} as any;
    if (!!dateRange.startDate) {
        filter = {
            ...filter,
            createdAt: !!filter?.createdAt
                ? { ...filter?.createdAt, $gte: dateRange.startDate }
                : { $gte: dateRange.startDate },
        };
    }
    if (!!dateRange.endDate) {
        filter = {
            ...filter,
            createdAt: !!filter?.createdAt ? { ...filter?.createdAt, $lt: dateRange.endDate } : { $lt: dateRange.endDate },
        };
    }
    return filter;
}

export const selectQueryString = (filters: IFilter, supplier?: string) => {
    let queryString = '?';
    const filter = {
        ...getSearchFilter(filters.search),
        ...getDateFilter(filters.dateRange),
        pharmacy: filters.pharmacy
    };
    if (supplier) {
        filter['supplier'] = supplier;
    }
    queryString += Object.keys(filter).length > 0 ? `filter=${JSON.stringify(filter)}&` : '';
    queryString += `limit=${filters.pagination.limit}&skip=${filters.pagination.skip}`;
    /*
    if (!!state.sorting.active) {
      queryString += `&sort=${state.sorting.direction === 'asc' ? '' : '-'}${state.sorting.active}`;
    } */
    return queryString;
};


// ----------------------------------------------------------------------

export function getPurchaseProducts(query: IFilter, supplier?: string) {
    return async (dispatch) => {
        //dispatch(slice.actions.startLoading());
        try {
            const queryString = selectQueryString(query, supplier)
            const response = await getPurchaseProductsFromBack(queryString)
            dispatch(slice.actions.getPuchasesSuccess(response));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function getPurchaseProduct(query: IFilter) {
    return async (dispatch) => {
        dispatch(slice.actions.startLoading());
        try {

            const queryString = selectQueryString(query)
            const response = await getPurchaseProductsFromBack(queryString)
            dispatch(slice.actions.getPuchaseSuccess(response.items[0]));
        } catch (error) {
            console.error(error);
            dispatch(slice.actions.hasError(error));
        }
    };
}


export function getPurchaseProductById(id) {
    return async (dispatch) => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await getPurchaseProductsFromBack(`?_id=${id}`)
            dispatch(slice.actions.getPuchaseSuccess(response.items[0]));
        } catch (error) {
            console.error(error);
            dispatch(slice.actions.hasError(error));
        }
    };
}


