import {
  IGenericProduct,
  PricingTypeEnum,
  ProductGroupEnum,
} from "lib/interfaces/generic-product";
import axios, { AxiosResponse } from "axios";
import { ExpensePaymentType } from "lib/interfaces/cash-register-expense";
import { CreateBatchDto } from "./batch";
const apiURL = process.env.REACT_APP_API_URL;
axios.interceptors.request.use(
  function (config) {
    config.headers.Authorization = `Bearer ${localStorage.getItem(
      process.env.REACT_APP_ACCESS_TOKEN || "medify_access_token"
    )}`;
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);
export interface CreateGenericProductDto {
  barcode: string;
  totalPriceWithIVAAndIEPS?: number;
  group: ProductGroupEnum;
  brand: string;
  batch?: boolean;
  description?: string;
  name: string;
  pricingType?: PricingTypeEnum;
  category?: string;
  price: number;
  cost: number;
  IVA: number;
  IEPS: number;
  quantity: number;
  controlled: boolean;
  product_key: string;
  supplier?: string;
  available?: boolean;
  imgUrl?: string;
  discount?: number;
}
export const validateIVA = (iva: number): boolean => {
  switch (iva) {
    case 0:
      return true;
    case 0.16:
      return true;
    case 0.11:
      return true;
    default:
      return false;
  }
};

export const validateIEPS = (IEPS: number): boolean => {
  switch (IEPS) {
    case 0:
      return true;
    case 0.08:
      return true;
    case 0.01:
      return true;
    case 0.03:
      return true;
    case 0.1:
      return true;
    case 0.21:
      return true;
    case 0.04:
      return true;
    case 0.23:
      return true;
    case 1.2:
      return true;
    case 1.6:
      return true;

    default:
      return true;
  }
};

export const taxesValidator = (
  dto: CreateGenericProductDto | UpdateGenericProductDto
) => {
  if (dto?.IVA) {
    if (!validateIVA(dto.IVA)) {
      throw new Error("IVA no valida");
    }
  }

  if (dto?.IEPS) {
    if (!validateIEPS(dto.IEPS)) {
      console.log("productDto", dto.IEPS);
      throw new Error("IEPS no valida");
    }
  }
  return true;
};

export const createGenericProduct = async (
  dto: CreateGenericProductDto
): Promise<IGenericProduct> => {
  try {
    taxesValidator(dto);
    const result: AxiosResponse = await axios.post(
      `${apiURL}/generic-product-pharmacy-db/product/cashier`,
      dto
    );
    const data: IGenericProduct = result.data;
    return data;
  } catch (err) {
    console.log(err);
    throw new Error(err);
  }
};

export interface UpdateGenericProductDto {
  barcode?: string;
  brand?: string;
  group?: ProductGroupEnum;
  description?: string;
  name?: string;
  price?: any;
  cost?: number;
  supplier?: string;
  IVA?: number;
  IEPS?: number;
  quantity?: any;
  controlled?: boolean;
  product_key?: string;
  available?: boolean;
  discount?: number;
  [x: string]: any;
}

export const updateGenericProduct = async (
  id: string,
  dto: UpdateGenericProductDto
): Promise<IGenericProduct> => {
  try {
    taxesValidator(dto);
    const result: void | AxiosResponse = await axios
      .put(`${apiURL}/generic-product-pharmacy-db/product/cashier/${id}`, dto)
      .catch((err: any) => {
        if (err?.response?.data?.errorCode === 3) {
          throw new Error("No tienes privilegios");
        }
      });
    const data: IGenericProduct = (result as AxiosResponse).data;
    return data;
  } catch (err) {
    console.log(err);
    throw new Error(err);
  }
};

export const getByBarcode = async (
  barcode: string,
  useRegex?: boolean
): Promise<IGenericProductResponse> => {
  try {
    let queryString = "";
    if (useRegex) {
      queryString = `${apiURL}/generic-product-pharmacy-db?barcode=/${barcode}/&limit=3`;
    } else {
      queryString = `${apiURL}/generic-product-pharmacy-db?barcode=${barcode}&limit=3`;
    }
    const result: void | AxiosResponse = await axios
      .get(queryString)
      .catch((err: any) => {
        if (err?.response?.data?.errorCode === 3) {
          throw new Error("No tienes privilegios");
        }
      });
    const data: IGenericProductResponse = (result as AxiosResponse).data;
    return data;
  } catch (err) {
    console.log(err);
    throw new Error(err);
  }
};

export interface BulkUpdateRestockGenericProductDto
  extends UpdateGenericProductDto {
  id: string;
  batches: CreateBatchDto[];
}

export interface BulkRestockCreateGenericProductDto
  extends CreateGenericProductDto {
  batches: CreateBatchDto[];
}
export interface CreateOrUpdateProductDto {
  new: BulkRestockCreateGenericProductDto[];
  restock: BulkUpdateRestockGenericProductDto[];
  supplier?: string;
  addToExpenses?: {
    externalInvoice?: string;
    externalOrderId?: string;
    expensePaymentType?: ExpensePaymentType;
    paymentDate: Date;
  };
}

export const createOrUpdateBulkProduct = async (
  dto: CreateOrUpdateProductDto,
  accessToken: string
): Promise<IGenericProduct[]> => {
  try {
    dto.new.forEach((product) => taxesValidator(product));
    dto.restock.forEach((product) => taxesValidator(product));
    const result: void | AxiosResponse = await axios
      .post(
        `${apiURL}/generic-product-pharmacy-db/products/create-and-update-many`,
        dto
      )
      .catch((err: any) => {
        if (err?.response?.data?.errorCode === 3) {
          throw new Error("No tienes privilegios");
        }
      });
    const data: IGenericProduct[] = (result as any).data;
    return data;
  } catch (err) {
    console.log(err);
    throw new Error(err);
  }
};

export interface createOrUpdateBulkProductFromCSVDto {
  brand?: string;
  supplier?: string;
  name: string;
  product_key: string;
  barcode: string;
  price: number;
  cost: number;
  IVA: number;
  IEPS: number;
  discount: number;
  quantity: number;
  description?: string;
}

export interface IGenericProductResponse {
  items: IGenericProduct[];
  count: number;
}

export const createOrUpdateBulkProductFromCSV = async (
  dto: createOrUpdateBulkProductFromCSVDto[]
): Promise<IGenericProductResponse> => {
  try {
    dto.forEach((product) => taxesValidator(product));
    dto.forEach((product) => taxesValidator(product));
    const result: void | AxiosResponse = await axios
      .post(`${apiURL}/generic-product-pharmacy-db/products/many`, dto)
      .catch((err: any) => {
        if (err?.response?.data?.errorCode === 3) {
          throw new Error("No tienes privilegios");
        }
      });
    const data: IGenericProductResponse = (result as any).data;
    return data;
  } catch (err) {
    console.log(err);
    throw new Error(err);
  }
};
export const BulkDeleteProduct = async (
  dto: string[],
  accessToken: string
): Promise<IGenericProduct[]> => {
  try {
    const result: void | AxiosResponse = await axios
      .delete(`${apiURL}/generic-product-pharmacy-db/cashier/many-products`, {
        data: { ids: dto },
      })
      .catch((err: any) => {
        if (err?.response?.data?.errorCode === 3) {
          throw new Error("No tienes privilegios");
        }
      });
    const data: IGenericProduct[] = (result as any).data;
    return data;
  } catch (err) {
    console.log(err);
    throw new Error(err);
  }
};
