import axios, { AxiosResponse } from "axios";
import { IPharmacy } from "lib/interfaces/pharmacy";
import { AddressInterface } from "./admin";

const apiURL = process.env.REACT_APP_API_URL;
axios.interceptors.request.use(
  function (config) {
    config.headers.Authorization = `Bearer ${localStorage.getItem(
      process.env.REACT_APP_ACCESS_TOKEN || "medify_access_token"
    )}`;
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

export const getPharmacy = async (id): Promise<IPharmacy> => {
  const result: AxiosResponse = await axios.get(`${apiURL}/pharmacy?_id=${id}`);
  const data: IPharmacy = result.data;
  return data;
};
export interface UpdatePharmacyDto {
  name: string;
  legalEntityName: string;
  email: string;
  phoneNumber: string;
  address: AddressInterface;
}
export const updatePharmacy = async (
  id: string,
  dto: UpdatePharmacyDto
): Promise<IPharmacy> => {
  const result: AxiosResponse = await axios.put(
    `${apiURL}/pharmacy/${id}`,
    dto
  );
  const data: IPharmacy = result.data;
  return data;
};

export class CreatePharmacyWithCashierDto {
  name: string;
  password: string;
  legalEntityName: string;
  rfc?: string;
  email: string;
  phoneNumber: string;
  address: AddressInterface;
}
export const createPharmacyWithCashier = async (
  dto: CreatePharmacyWithCashierDto
): Promise<IPharmacy> => {
  const result: AxiosResponse = await axios.post(
    `${apiURL}/pharmacy/with-cashier`,
    dto
  );
  const data: IPharmacy = result.data;
  return data;
};
