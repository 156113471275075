// import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { Box } from '@material-ui/core';

// ----------------------------------------------------------------------

// MotionInView.propTypes = {
//   children: PropTypes.node,
//   className: PropTypes.string,
//   variant: PropTypes.object,
//   transition: PropTypes.object,
//   triggerOnce: PropTypes.bool,
//   threshold: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
// };

export interface IMotionViewProps {
  children?: React.ReactNode;
  className?: string;
  variant?: any;
  transition?: any;
  triggerOnce?: boolean;
  treshold: string | any[];
  [key: string]: any;
}

function MotionInView({
  children,
  className,
  variants,
  transition,
  threshold,
  ...other
}: IMotionViewProps) {
  const controls = useAnimation();
  const [ref, inView] = useInView({
    threshold: threshold ? threshold : 0,
    triggerOnce: true,
  });

  useEffect(() => {
    if (inView) {
      controls.start(Object.keys(variants)[1]);
    } else {
      controls.start(Object.keys(variants)[0]);
    }
  }, [controls, inView, variants]);

  return (
    <Box
      component={motion.div}
      className={className}
      {...{
        ref: ref as any,
        animate: controls,
        variants: variants,
        transition: transition,
        initial: Object.keys(variants)[0],
      }}
      {...other}
    >
      {children}
    </Box>
  );
}

export default MotionInView;
