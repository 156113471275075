import React, { useEffect, useState } from "react";
import { Card, Container, Grid, Typography, Link } from "@material-ui/core";
import OndemandVideoIcon from "@material-ui/icons/OndemandVideo";
import { useLocation } from "react-router-dom";

var Airtable = require("airtable");

Airtable.configure({
  endpointUrl: "https://api.airtable.com",
  apiKey: "keyeKJbMXdKfgxHM1",
});

const Tutorial = () => {
  let base = Airtable.base("appDpr3RnYZEtydp8");
  const [title, setTitle] = useState(null);
  const [description, setDescription] = useState(null);
  const [hasVideo, setHasVideo] = useState(null);
  const [videoUrl, setVideoUrl] = useState(null);

  const { pathname } = useLocation();

  const fetchAndProcessAirtableData = () => {
    setTitle(null);
    base("Table 1")
      .select({
        maxRecords: 100,
        view: "Grid view",
      })
      .eachPage(
        function page(records, fetchNextPage) {
          records.forEach(function (record) {
            if (record.get("baseUrl") === pathname) {
              setTitle(record.get("titleText"));
              setDescription(record.get("longText"));
              setHasVideo(record.get("hasVideo"));
              setVideoUrl(record.get("videoUrl"));
            }
          });
          fetchNextPage();
        },
        function done(err) {
          if (err) {
            console.error(err);
            return;
          }
        }
      );
  };

  useEffect(() => {
    fetchAndProcessAirtableData();
    // eslint-disable-next-line
  }, [pathname]);

  return (
    <>
      {title && (
        <Container style={{ marginBottom: "1em" }}>
          <Card style={{ padding: "2em" }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h4">{title}</Typography>
              </Grid>
              <Grid item xs={12}>
                {description}
              </Grid>
              {hasVideo && (
                <Grid item xs={12}>
                  <OndemandVideoIcon />
                  <Link
                    onClick={() => {
                      window.open(videoUrl);
                    }}
                    style={{ marginLeft: "1em" }}
                  >
                    Ver en Youtube
                  </Link>
                </Grid>
              )}
            </Grid>
          </Card>
        </Container>
      )}
    </>
  );
};

export default Tutorial;
