import axios, { AxiosResponse } from 'axios';
import { ExpensePaymentType, ExpenseType, ICashRegisterExpense } from 'lib/interfaces/cash-register-expense';
import { IKPI } from 'lib/interfaces/kpi';
const apiURL = process.env.REACT_APP_API_URL;
axios.interceptors.request.use(function (config) {
    config.headers.Authorization = `Bearer ${localStorage.getItem(process.env.REACT_APP_ACCESS_TOKEN || 'medify_access_token')}`;
    return config;
}, function (error) {
    // Do something with request error
    return Promise.reject(error);
});

export interface CreateExpenseDto {
    amount: number;
    canInvoiceBeCreated?: boolean;
    expenseType: ExpenseType;
    supplier?: string;
    notes?: string;
    imss?: number;
    expensePaymentType: ExpensePaymentType;
    paymentDate?: Date;
}

export interface UpdateExpenseDto {
    amount?: number;
    canInvoiceBeCreated?: boolean;
    expenseType?: ExpenseType;
    supplier?: string;
    notes?: string;
    imss?: number;
    expensePaymentType?: ExpensePaymentType;
    paymentDate?: Date;
}

export interface ResponseCreateCashRegisterExpense {
    count: number;
    items: ICashRegisterExpense[];
}

export const createExpenseInBack = async (dto: CreateExpenseDto, accessToken: string): Promise<ResponseCreateCashRegisterExpense> => {
    try {
        const result: void | AxiosResponse = await axios.post(`${apiURL}/cash-register-expense`, dto, { headers: { 'Authorization': `Bearer ${accessToken}` } }).catch((err: any) => {
            if (err?.response?.data?.errorCode === 3) {
                throw new Error('No tienes privilegios')
            }
        })
        const data: ResponseCreateCashRegisterExpense = (result as any).data;
        return data
    } catch (err) {
        console.log(err)
        throw new Error(err)
    }
}

export const getAvailableCashRegisterExpenses = async (accessToken: string): Promise<ResponseCreateCashRegisterExpense> => {
    try {
        const result: void | AxiosResponse = await axios.get(`${apiURL}/cash-register-cuts/available-expenses`, { headers: { 'Authorization': `Bearer ${accessToken}` } }).catch((err: any) => {
            if (err?.response?.data?.errorCode === 3) {
                throw new Error('No tienes privilegios')
            }
        })
        const data: ResponseCreateCashRegisterExpense = (result as any).data;
        return data
    } catch (err) {
        console.log(err)
        throw new Error(err)
    }

}

export const getExpensesFromBack = async (queryString: string): Promise<ResponseCreateCashRegisterExpense> => {
    try {
        const result: void | AxiosResponse = await axios.get(`${apiURL}/cash-register-expense${queryString}&populate=account&sort=-createdAt`).catch((err: any) => {
            if (err?.response?.data?.errorCode === 3) {
                throw new Error('No tienes privilegios')
            }
        })
        const data: ResponseCreateCashRegisterExpense = (result as any).data;
        return data
    } catch (err) {
        console.log(err)
        throw new Error(err)
    }

}

export const getExpensesResultFromBack = async (startDate: Date, endDate: Date): Promise<IKPI> => {
    try {
        const result: void | AxiosResponse = await axios.get(`${apiURL}/cash-register-expense/kpi/aggregated-results?startDate=${startDate}&endDate=${endDate}`).catch((err: any) => {
            if (err?.response?.data?.errorCode === 3) {
                throw new Error('No tienes privilegios')
            }
        })
        const data: IKPI = (result as any).data;
        return data
    } catch (err) {
        console.log('errror', err)
        //  throw new Error(err)
    }
}

export const updateExpense = async (id: string, dto: UpdateExpenseDto, accessToken: string): Promise<ICashRegisterExpense> => {
    try {
        const result: void | AxiosResponse = await axios.put(`${apiURL}/cash-register-expense/${id}`, dto).catch((err: any) => {
            if (err?.response?.data?.errorCode === 3) {
                throw new Error('No tienes privilegios')
            }
        })
        const data: ICashRegisterExpense = (result as any).data;
        return data
    } catch (err) {
        console.log(err)
        throw new Error(err)
    }
}